export default {
  name: "E808CTriangle",
  props: {
    colors: {
      type: String,
      default: 'RGBRGBZXY'
    }
  },
  data() {
    return {
      flags: {
        'X': '+',
        'Y': '÷',
        'Z': '÷'
      },
      color: {
        'R': 'red',
        'G': 'green',
        'B': 'blue'
      }
    };
  },
  methods: {
    firstColor() {
      for (let c of this.colors) {
        if (c != '?') {
          return this.color[c];
        }
      }
    },
    init() {}
  },
  mounted() {
    this.init();
  }
};