import api from "@/api";
import QuestionTplSelector from "@/components/QuestionTplSelector";
export default {
  name: "TeachingPlanEditorView",
  components: {
    QuestionTplSelector
  },
  data() {
    return {
      planId: this.$route.params.planId,
      plan: {
        tplIds: []
      },
      map: {},
      mapping: {},
      drawer: false
    };
  },
  methods: {
    deleteTplFromPlan(tplId) {
      api.deleteTplFromPlan({
        planId: this.planId,
        'tplId': tplId
      }).then(() => {
        this.init();
      });
    },
    onSelected(tplId) {
      api.addTpl2ClassPlan({
        planId: this.planId,
        'tplId': tplId
      }).then(() => {
        this.init();
        this.drawer = false;
      });
    },
    init() {
      api.getTeachPlanInfo(this.planId).then(ret => {
        this.plan = ret.plan;
        this.mapping = ret.mapping;
        this.map = ret.map;
      });
    }
  },
  mounted() {
    this.init();
  }
};