export default {
  name: "WatermarkerView",
  data() {
    return {
      watermakerUrl: null
    };
  },
  methods: {
    init() {
      const waterMark = localStorage.getItem('waterMarker');
      const res = `
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="240px" height="140px" viewBox="0 0 180 180">
        <text x="-100" y="-30" fill='#000'  transform = "rotate(-35 220 -220)" fill-opacity='0.08' font-size='18'> ${waterMark}</text>
      </svg>`;
      const blob = new Blob([res], {
        type: 'image/svg+xml'
      });
      this.watermakerUrl = URL.createObjectURL(blob);
    }
  },
  mounted() {
    this.init();
  }
};