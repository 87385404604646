export default {
  name: "FlashingCircleGraph",
  props: {
    path: {
      type: String,
      default: '0000'
    }
  },
  methods: {
    init() {}
  },
  mounted() {
    this.init();
  }
};