import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3e74345a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "LeftGroupListContainer"
};
const _hoisted_2 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_divider = _resolveComponent("el-divider");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$props.groupTitle ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 0
  }, [_createVNode(_component_el_row, {
    class: "pd12"
  }, {
    default: _withCtx(() => [_createElementVNode("span", null, _toDisplayString($props.groupTitle), 1)]),
    _: 1
  }), _createVNode(_component_el_divider, {
    style: {
      "margin": "0"
    }
  })], 64)) : _createCommentVNode("", true), _createVNode(_component_el_row, {
    class: "colFlex pd12"
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.data, g => {
      return _openBlock(), _createElementBlock("span", {
        key: g.value,
        onClick: $event => $options.choose(g.value),
        class: _normalizeClass({
          'ft14 vpd8 ptr': g.value != $data.selected,
          'ft14 vpd8 ptr active': g.value == $data.selected
        })
      }, _toDisplayString(g.text), 11, _hoisted_2);
    }), 128))]),
    _: 1
  })]);
}