import SeesawLoad from "@/components/subject/Seesaw/SeesawLoad";
export default {
  name: "SeesawImage",
  components: {
    SeesawLoad
  },
  props: {
    data: {
      type: String,
      default: null
    }
  },
  watch: {
    data() {
      this.init();
    }
  },
  data() {
    return {
      flag: null,
      left: [],
      right: []
    };
  },
  methods: {
    init() {
      if (!this.data) {
        return;
      }
      let noBlank = this.data.replace(/\s+/g, "");
      if (noBlank.includes('>')) {
        let ps = noBlank.split('>');
        this.left = ps[0].split('+');
        this.right = ps[1].split('+');
        this.flag = '>';
      } else if (noBlank.includes('<')) {
        let ps = noBlank.split('<');
        this.left = ps[0].split('+');
        this.right = ps[1].split('+');
        this.flag = '<';
      } else if (noBlank.includes('=')) {
        let ps = noBlank.split('=');
        this.left = ps[0].split('+');
        this.right = ps[1].split('+');
        this.flag = '=';
      }
    }
  },
  mounted() {
    this.init();
  }
};