import "core-js/modules/es.array.push.js";
import api from "@/api";
import { mapMutations } from "vuex";
// import md5 from 'js-md5'

export default {
  name: "LoginPage",
  data() {
    return {
      cooVisible: false,
      loginMode: 0,
      loginModel: {
        account: null,
        password: null
      },
      passwordError: null,
      accountError: null
    };
  },
  methods: {
    ...mapMutations(['updateUser']),
    doLogin() {
      let verify = true;
      if (!this.loginModel.account) {
        this.accountError = '账号必填';
        verify = false;
      }
      if (!this.loginModel.password) {
        this.passwordError = '密码必填';
        verify = false;
      }
      if (!verify) {
        return;
      }
      this.accountError = null;
      this.passwordError = null;
      api.login({
        username: this.loginModel.account,
        // password: md5(this.loginModel.password),
        password: this.loginModel.password,
        channel: 0
      }).then(ret => {
        localStorage.setItem('access_token', ret);
        this.updateUser(ret);
        // 取到原页面路径
        let url = this.$route.query.redirect;
        // 跳转回原页面
        this.$router.push({
          path: decodeURIComponent(url ? url : '/')
        });
      }).catch(err => {
        console.log('login err', err);
      });
    }
  },
  mounted() {}
};