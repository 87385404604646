import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7867beec"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "MossAnswerOptionContainer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_MossSymbol = _resolveComponent("MossSymbol");
  const _component_el_tag = _resolveComponent("el-tag");
  const _component_el_row = _resolveComponent("el-row");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_MossSymbol, {
    symbol: $props.symbol
  }, null, 8, ["symbol"]), _createVNode(_component_el_row, {
    class: "mt16"
  }, {
    default: _withCtx(() => [(_openBlock(), _createElementBlock(_Fragment, null, _renderList([0, 1, 2, 3, 4, 5, 6, 7, 8, 9], i => {
      return _createVNode(_component_el_tag, {
        style: {
          "width": "10px"
        },
        type: $data.selected == i ? 'primary' : 'info',
        onClick: $event => $options.select(i),
        class: "ptr",
        key: i
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(i), 1)]),
        _: 2
      }, 1032, ["type", "onClick"]);
    }), 64))]),
    _: 1
  })]);
}