export default {
  name: "HamsterCard",
  props: {
    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      visible: false
    };
  },
  methods: {
    play(time) {
      this.visible = true;
      setTimeout(() => {
        this.visible = false;
      }, time);
    },
    init() {}
  },
  mounted() {
    this.init();
  }
};