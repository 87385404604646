import MossSymbol from "@/components/subject/Moss/MossSymbol";
export default {
  name: "MossAnswerOption",
  components: {
    MossSymbol
  },
  props: {
    symbol: {
      type: String,
      default: ''
    },
    review: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      selected: null
    };
  },
  methods: {
    select(i) {
      if (this.review != null) {
        return;
      }
      this.selected = i;
      this.$emit('select', i);
    },
    init() {
      this.selected = this.review;
    }
  },
  mounted() {
    this.init();
  }
};