import "core-js/modules/es.array.push.js";
export default {
  name: "AnswerCard",
  props: {
    session: {
      type: Object,
      default: () => {
        return {};
      }
    },
    width: {
      type: Number,
      default: 4
    },
    current: {
      type: Number,
      default: 0
    },
    groupSize: {
      type: Number,
      default: 1
    }
  },
  watch: {
    session() {
      this.init();
    },
    current() {
      this.init();
    }
  },
  data() {
    return {
      questions: [],
      //[true,true,false,false,false,false,]
      leftSeconds: 0,
      timeEndPercent: 0,
      leftTimeTask: null
    };
  },
  methods: {
    init() {
      // console.log('groupSize', this.groupSize)
      this.questions = [];
      for (let i = 0; i < this.session.total / this.groupSize; i++) {
        if (i < this.current / this.groupSize) {
          this.questions.push(true);
        } else {
          this.questions.push(false);
        }
      }
      this.leftSeconds = 0;
      this.timeEndPercent = 0;
      this.leftTimeTask = null;
      this.stopCalcLeftTime(true);
      this.calcLeftTime();
      this.leftTimeTask = setInterval(() => {
        this.calcLeftTime();
      }, 1000);
    },
    secondsToMMSS(seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      const formattedMinutes = String(minutes).padStart(2, '0');
      const formattedSeconds = String(remainingSeconds).padStart(2, '0');
      return `${formattedMinutes}:${formattedSeconds}`;
    },
    calcLeftTime() {
      if (this.session.state != 'TESTING') {
        //不在考试态，结束
        this.stopCalcLeftTime(false);
        return;
      }
      let totalSeconds = (this.session.endTimeStamp - this.session.startTimeStamp) / 1000;
      this.leftSeconds = Math.max(0, (this.session.endTimeStamp - new Date().getTime()) / 1000).toFixed(0);
      this.$store.commit('updateExamLeftTime', this.secondsToMMSS(this.leftSeconds));
      this.timeEndPercent = 100 - parseInt(this.leftSeconds * 100 / totalSeconds);
      if (this.leftSeconds <= 0) {
        this.stopCalcLeftTime(false);
      }
    },
    stopCalcLeftTime(_init_) {
      if (this.leftTimeTask) {
        clearInterval(this.leftTimeTask);
      }
      this.timeEndPercent = 100;
      if (!_init_) {
        this.$emit('timeup');
      }
    }
  },
  mounted() {
    this.init();
  },
  unmounted() {
    console.log('清理计时器');
    this.stopCalcLeftTime(true);
  }
};