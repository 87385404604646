import "core-js/modules/es.array.push.js";
import api from "@/api";
import WatermarkerView from "@/components/WatermarkerView";
export default {
  name: "HomeView",
  components: {
    WatermarkerView
  },
  data() {
    return {
      tab: 0,
      user: {}
    };
  },
  mounted() {
    api.getUserInfo().then(ret => {
      this.user = ret.info;
      if ('ADMIN' == ret.info.userTag) {
        localStorage.setItem('waterMarker', '');
      } else {
        localStorage.setItem('waterMarker', `${ret.info.nickName}${ret.info.phone}`);
      }
    });
    if (this.$route.path.startsWith('/class/room')) {
      this.tab = 1;
    } else if (this.$route.path.startsWith('/answer/history')) {
      this.tab = 2;
    } else if (this.$route.path.startsWith('/home/work')) {
      this.tab = 3;
    } else if (this.$route.path.startsWith('/class/work')) {
      this.tab = 4;
    } else {
      this.tab = 0;
    }
  },
  methods: {
    goTab(tabIndex) {
      this.tab = tabIndex;
      if (tabIndex == 0) {
        this.$router.push('/');
      } else if (tabIndex == 1) {
        this.$router.push('/class/room');
      } else if (tabIndex == 2) {
        this.$router.push('/answer/history');
      } else if (tabIndex == 3) {
        this.$router.push('/home/work');
      } else if (tabIndex == 4) {
        this.$router.push('/class/work');
      }
    },
    logout() {
      localStorage.removeItem('access_token');
      // api.logout().then(() => {
      this.$router.go(0);
      // })
    }
  }
};