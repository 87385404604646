export default {
  name: "MossSymbol",
  props: {
    symbol: {
      type: String,
      default: ''
    }
  },
  methods: {
    init() {}
  },
  mounted() {
    this.init();
  }
};