import api from "@/api";
import AsideMenu from "@/components/AsideMenu";
export default {
  name: "HomeView",
  components: {
    AsideMenu
  },
  data() {
    return {
      tab: 0,
      user: {}
    };
  },
  mounted() {
    api.getUserInfo().then(ret => {
      this.user = ret.info;
    });
  },
  methods: {
    logout() {
      localStorage.removeItem('access_token');
      // api.logout().then(() => {
      this.$router.go(0);
      // })
    }
  }
};