export default {
  name: "LeftGroupList",
  props: {
    groupTitle: {
      type: String,
      default: null
    },
    data: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  watch: {
    data() {
      this.init();
    }
  },
  data() {
    return {
      selected: null
    };
  },
  methods: {
    choose(gid) {
      this.selected = gid;
      this.$emit('choose', gid);
    },
    init() {
      this.selected = this.data.length > 0 ? this.data[0].value : null;
    }
  },
  mounted() {
    this.init();
  }
};