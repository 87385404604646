import AnswerButtonGroup from "@/components/train/AnswerButtonGroup.vue";
export default {
  name: "LogicalReasoningRender",
  components: {
    AnswerButtonGroup
  },
  props: {
    review: {
      type: Object,
      default: () => {
        return null;
      }
    },
    reviewMode: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {
        return null;
      }
    }
  },
  watch: {
    data() {
      this.init();
    }
  },
  data() {
    return {
      answer: null
    };
  },
  methods: {
    onChoosed(l) {
      this.$emit('answer', l && l.length > 0 ? l[0] : null);
    },
    init() {
      this.answer = null;
    }
  },
  mounted() {
    this.init();
  }
};