import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2749ea20"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "AnswerHistoryViewContainer wd100"
};
const _hoisted_2 = {
  key: 0
};
const _hoisted_3 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button");
  const _component_BatterTableDataView = _resolveComponent("BatterTableDataView");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_BatterTableDataView, {
    "table-id": 7
  }, {
    subject: _withCtx(data => [_createElementVNode("span", null, _toDisplayString(data.mapping[data.rowData]), 1)]),
    finishTimestamp: _withCtx(data => [data.data.state == 'TESTING' ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString($options.millisecondsToMinutesAndSeconds(new Date().getTime() - data.data.created)), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString($options.millisecondsToMinutesAndSeconds(data.rowData - data.data.created)), 1))]),
    accuracy: _withCtx(data => [_createElementVNode("span", null, _toDisplayString(data.data.rightAnswerCnt + data.data.wrongAnswerCnt > 0 ? (data.data.rightAnswerCnt * 100.0 / (data.data.rightAnswerCnt + data.data.wrongAnswerCnt)).toFixed(2) : '-'), 1)]),
    score: _withCtx(data => [_createElementVNode("span", null, _toDisplayString((data.data.rightAnswerCnt / data.data.totalQuestion * 100).toFixed(0)), 1)]),
    operate: _withCtx(data => [data.data.state != 'TESTING' ? (_openBlock(), _createBlock(_component_el_button, {
      key: 0,
      type: "success",
      size: "small",
      onClick: $event => $options.go(data.data)
    }, {
      default: _withCtx(() => [_createTextVNode("回看结果 ")]),
      _: 2
    }, 1032, ["onClick"])) : (_openBlock(), _createBlock(_component_el_button, {
      key: 1,
      type: "primary",
      size: "small",
      onClick: $event => $options.go(data.data)
    }, {
      default: _withCtx(() => [_createTextVNode("继续答题")]),
      _: 2
    }, 1032, ["onClick"]))]),
    _: 1
  })]);
}