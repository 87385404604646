import api from "@/api";
export default {
  name: "DynaQueryForm",
  props: {
    buttonAreaWidth: {
      type: Number,
      default: 24
    },
    formId: {
      type: Number,
      default: 0
    },
    title: {
      type: String,
      default: '搜索条件'
    },
    initQuery: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      colWidth: 12,
      form: [],
      query: {},
      dataSource: {},
      dataSourceMap: {},
      clearKeys: {},
      addButton: false
    };
  },
  methods: {
    fnLazyLoad(itemKey, source, node, resolve) {
      let query = {};
      if (node.level > 0) {
        query[itemKey] = node.value;
      }
      api.get(source, query).then(ret => {
        if (node.level >= 3) {
          //只到达社区级
          for (let r of ret) {
            r.leaf = true;
          }
        }
        resolve(ret);
      });
    },
    onReset() {
      this.query = {};
      this.$emit('onReset');
    },
    addMore() {
      this.$emit('addMore');
    },
    queryDataSource(dataKey) {
      // console.log('current',this.query);
      if (dataKey && this.clearKeys[dataKey]) {
        //需要清除其他key的情形
        for (let cl of this.clearKeys[dataKey]) {
          this.query[cl] = null;
        }
      }
      for (let k of Object.keys(this.dataSourceMap)) {
        if (dataKey && dataKey === k) {
          //当前key不更新
          continue;
        }
        api.get(this.dataSourceMap[k], this.query).then(ret => {
          this.dataSource[k] = ret;
        });
      }
    },
    submit() {
      this.$emit('onQuery', this.query);
    }
  },
  mounted() {
    if (this.formId < 1) {
      return;
    }
    api.getQueryForm(this.formId, this.initQuery).then(ret => {
      this.form = ret.form;
      this.colWidth = ret.colWidth;
      this.addButton = ret.addButton;
      for (let f of ret.form) {
        if (f['dataSource']) {
          this.dataSourceMap[f.name] = f.dataSource;
        }
        if (f['clearKeys']) {
          this.clearKeys[f.name] = f.clearKeys;
        }
        if (f.type === 'datepicker') {
          if (f.value) {
            this.query[f.name] = f.value;
          }
        }
      }
      this.queryDataSource();
    });
  }
};