import BatterTableDataView from "@/components/BatterTableDataView";
export default {
  name: "AnswerHistoryView",
  components: {
    BatterTableDataView
  },
  methods: {
    millisecondsToMinutesAndSeconds(milliseconds) {
      const totalSeconds = Math.floor(milliseconds / 1000);
      const minutes = Math.floor(totalSeconds / 60);
      const seconds = totalSeconds % 60;
      return minutes > 0 ? `${minutes}分${seconds}秒` : `${seconds}秒`;
    },
    go(session) {
      window.open(`/#/train/${session.subject}/${session.examId}`, '_blank');
    },
    init() {}
  },
  mounted() {
    this.init();
  }
};