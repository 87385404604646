import "core-js/modules/es.array.push.js";
import api from "@/api";
import BatterTableDataView from "@/components/BatterTableDataView";
import ButtonDialog from "@/components/ButtonDialog";
export default {
  name: "ClassRoomDetailView",
  components: {
    BatterTableDataView,
    ButtonDialog
  },
  data() {
    return {
      roomId: this.$route.params.roomId,
      tplMap: {},
      room: {},
      plan: {},
      unlock: {},
      subjectMap: {},
      offTpls: null,
      activeSubject: null,
      activeOffSubject: null,
      activeTplId: null
    };
  },
  methods: {
    lookAnswerDetail(tplId) {
      if (!this.unlock[tplId]) {
        return;
      }
      this.$router.push(`/m/teaching/class/answer/${this.roomId}/${tplId}/0`);
    },
    lookOffAnswerDetail(tplId) {
      this.$router.push(`/m/teaching/class/answer/${this.roomId}/${tplId}/1`);
    },
    activeTpl(tpl, val) {
      if (!val) {
        //开启后不允许关闭
        return;
      }
      api.enableClassTpl({
        roomId: this.roomId,
        'tplId': tpl
      }).then(() => {
        this.init();
      });
    },
    onSubjectClick(subject) {
      this.activeSubject = subject;
    },
    onOffSubjectClick(subject) {
      this.activeOffSubject = subject;
    },
    onTplClick(tplId) {
      this.activeTplId = tplId;
    },
    init() {
      api.getClassRoomInfo(this.roomId).then(ret => {
        this.tplMap = ret.tplMap;
        this.room = ret.room;
        this.plan = ret.plan;
        this.unlock = ret.unlock;
        this.subjectMap = ret.subjectMap;
        this.offTpls = ret.offTpls;
      });
    }
  },
  mounted() {
    this.init();
  }
};