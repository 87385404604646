export default {
  name: "E808B2BtnGroup",
  props: {
    review: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      values: [null, null]
    };
  },
  methods: {
    select(pos, val) {
      if (this.review != null) {
        return;
      }
      this.values[pos] = val;
      if (this.values[0] != null && this.values[1] != null) {
        this.$emit('answer', `${this.values[0]}${this.values[1]}`);
      } else {
        this.$emit('answer', null);
      }
    },
    init() {
      if (this.review != null) {
        this.values[0] = parseInt(this.review.charAt(0));
        this.values[1] = parseInt(this.review.charAt(1));
      }
    }
  },
  mounted() {
    this.init();
  }
};