import LeftGroupList from "@/components/LeftGroupList";
import ButtonDialog from "@/components/ButtonDialog";
import api from "@/api";
import SeesawImage from "@/components/subject/Seesaw/SeesawImage";
export default {
  name: "SeesawQuestionView",
  components: {
    SeesawImage,
    LeftGroupList,
    ButtonDialog
  },
  data() {
    return {
      groups: [],
      groupId: null,
      questions: [],
      typeMap: {}
    };
  },
  methods: {
    deleteGroup() {
      api.deleteSeesawGroup(this.groupId).then(() => {
        this.init();
      });
    },
    deleteSeesawQuestion(qid) {
      api.deleteSeesawQuestion(qid).then(() => {
        this.fetchList();
      });
    },
    onChoose(gid) {
      this.groupId = gid;
      this.fetchList();
    },
    fetchList() {
      api.getSeesawByGroupId(this.groupId).then(ret => {
        this.questions = ret.list;
      });
    },
    init() {
      api.getSeesawGroups().then(list => {
        this.groups = list;
        this.groupId = list[0].value;
        this.fetchList();
      });
    }
  },
  mounted() {
    this.init();
  }
};