import E808DTriangle from "@/components/subject/E808D/E808DTriangle";
export default {
  name: "E808DRender",
  components: {
    E808DTriangle
  },
  props: {
    review: {
      type: Object,
      default: () => {
        return null;
      }
    },
    reviewMode: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {
        return null;
      }
    }
  },
  watch: {
    data() {
      this.init();
    }
  },
  data() {
    return {
      values: {}
    };
  },
  methods: {
    init() {},
    checkAnswer() {
      console.log('checkAnswer', this.values);
      for (let q of this.data) {
        if (this.values[q.questionIndex] == null || this.values[q.questionIndex] == '') {
          this.$emit('answer', null);
          return;
        }
      }
      this.$emit('answer', this.values);
    }
  },
  mounted() {
    this.init();
  }
};