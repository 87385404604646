import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-994ff6e6"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "MossSymbolContainer bothCenter"
};
const _hoisted_2 = {
  key: 0,
  src: "https://edudata.computingis.fun/point.png",
  class: "sqr20"
};
const _hoisted_3 = {
  key: 1,
  src: "https://edudata.computingis.fun/heng.png",
  class: "sqr20"
};
const _hoisted_4 = {
  key: 2,
  src: "https://edudata.computingis.fun/line.png",
  class: "sqr20"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.symbol, (s, si) => {
    return _openBlock(), _createElementBlock(_Fragment, {
      key: si
    }, [s == '0' ? (_openBlock(), _createElementBlock("img", _hoisted_2)) : _createCommentVNode("", true), s == '1' ? (_openBlock(), _createElementBlock("img", _hoisted_3)) : _createCommentVNode("", true), s == '2' ? (_openBlock(), _createElementBlock("img", _hoisted_4)) : _createCommentVNode("", true)], 64);
  }), 128))]);
}