export default {
  name: "DoubleChoiceBtnGroup",
  props: {
    review: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      values: [false, false, false, false],
      clickCnt: 0,
      answer: [null, null]
    };
  },
  methods: {
    click(idx) {
      if (this.review != null) {
        return;
      }
      if (this.values[idx]) {
        //clear
        if (this.answer[0] == idx) {
          this.answer[0] = null;
        } else {
          this.answer[1] = null;
        }
        this.values[idx] = false;
      } else {
        this.values[idx] = true;
        let pos = this.clickCnt++ % 2;
        if (this.answer[pos] != null) {
          this.values[this.answer[pos]] = false;
        }
        this.answer[pos] = idx;
      }
      if (this.answer[0] != null && this.answer[1] != null) {
        if (this.answer[0] > this.answer[1]) {
          this.$emit('answer', `${this.answer[1]}${this.answer[0]}`);
        } else {
          this.$emit('answer', `${this.answer[0]}${this.answer[1]}`);
        }
      } else if (this.answer[0] != null) {
        this.$emit('answer', `${this.answer[0]}`);
      } else if (this.answer[1] != null) {
        this.$emit('answer', `${this.answer[1]}`);
      } else {
        this.$emit('answer', null);
      }
    },
    init() {
      this.values = [false, false, false, false];
      if (this.review != null) {
        for (let char of this.review) {
          this.values[parseInt(char)] = true;
        }
      }
    }
  },
  mounted() {
    this.init();
  }
};