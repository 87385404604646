import "core-js/modules/es.array.push.js";
export default {
  name: "OrderedAnswerButtonGroup",
  props: {
    optionWidth: {
      type: String,
      default: '100px'
    },
    optionBothCenter: {
      type: Boolean,
      default: true
    },
    review: {
      type: Object,
      default: () => {
        return null;
      }
    },
    reviewMode: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  watch: {
    options() {
      this.init();
    }
  },
  data() {
    return {
      letters: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
      selected: []
    };
  },
  methods: {
    deleteLast() {
      if (this.selected.length > 0) {
        this.selected.splice(this.selected.length - 1, 1);
        this.$emit('choose', this.selected);
      }
    },
    onClick(op) {
      if (this.review != null) {
        return;
      }
      this.selected.push(op);
      this.$emit('choose', this.selected);
    },
    init() {
      this.selected = [];
      if (this.review != null) {
        this.selected = this.review.answer.split(',');
      }
    }
  },
  mounted() {
    this.init();
  }
};