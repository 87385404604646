import AnswerButtonGroup from "@/components/train/AnswerButtonGroup";
import E808ETriangle from "@/components/subject/E808E/E808ETriangle";
export default {
  name: "E808EPlusRender",
  components: {
    E808ETriangle,
    AnswerButtonGroup
  },
  props: {
    review: {
      type: Object,
      default: () => {
        return null;
      }
    },
    reviewMode: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {
        return null;
      }
    }
  },
  data() {
    return {
      example: 0,
      letters: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
    };
  },
  watch: {
    data() {
      this.init();
    }
  },
  methods: {
    init() {},
    onChoosed(l) {
      this.$emit('answer', l && l.length > 0 ? l.join(',') : null);
    }
  },
  mounted() {
    this.init();
  }
};