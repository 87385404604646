import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7fb44f28"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "NineFlashingBoardContainer"
};
const _hoisted_2 = {
  class: "ft14"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_row = _resolveComponent("el-row");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, {
    class: "bothCenter outBoard"
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.data, b => {
      return _openBlock(), _createBlock(_component_el_col, {
        span: 8,
        class: "frbd bothCenter",
        style: {
          "height": "65px"
        },
        key: b
      }, {
        default: _withCtx(() => [_createElementVNode("span", _hoisted_2, _toDisplayString($data.flashing[b]), 1)]),
        _: 2
      }, 1024);
    }), 128))]),
    _: 1
  })]);
}