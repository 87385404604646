import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2c5b837e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "colFlex bothCenter"
};
const _hoisted_2 = ["id"];
const _hoisted_3 = {
  class: "bothCenter mt12 mb12"
};
const _hoisted_4 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_tag = _resolveComponent("el-tag");
  const _component_el_button = _resolveComponent("el-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    id: $data.containerId,
    class: "container3d"
  }, null, 8, _hoisted_2), _createElementVNode("div", _hoisted_3, [$props.isSvae ? (_openBlock(), _createElementBlock("div", _hoisted_4, [($props.isDesc ? $props.previewImages?.question : $props.previewImages?.answers?.[$props.localIndex]) ? (_openBlock(), _createBlock(_component_el_tag, {
    key: 0,
    style: {
      "margin-right": "10px"
    },
    type: "success"
  }, {
    default: _withCtx(() => [_createTextVNode(" 已上传 ")]),
    _: 1
  })) : _createCommentVNode("", true), _createVNode(_component_el_button, {
    size: "small",
    type: "primary",
    onClick: $options.handleSaveImage
  }, {
    default: _withCtx(() => [_createTextVNode(" 保存图片 ")]),
    _: 1
  }, 8, ["onClick"])])) : _createCommentVNode("", true)])]);
}