import "core-js/modules/es.array.push.js";
import BatterTableDataView from "@/components/BatterTableDataView";
import ButtonDialog from "@/components/ButtonDialog";
export default {
  name: "TeachingClassView",
  components: {
    ButtonDialog,
    BatterTableDataView
  },
  methods: {
    onDetailData(r) {
      this.$router.push('/m/teaching/class/room/' + r.id);
    },
    init() {}
  },
  mounted() {
    this.init();
  }
};