import "core-js/modules/es.array.push.js";
import LeftGroupList from "@/components/LeftGroupList";
import api from "@/api";
import BatterTableDataView from "@/components/BatterTableDataView";
import ButtonDialog from "@/components/ButtonDialog";
export default {
  name: "QuestionTplView",
  components: {
    ButtonDialog,
    BatterTableDataView,
    LeftGroupList
  },
  data() {
    return {
      groups: [],
      historyGroups: [],
      groupId: 0
    };
  },
  methods: {
    updateRandomPractice(tplId) {
      api.updateRandomPractice(tplId).then(() => {
        this.$refs.tplTable.fetchData();
      });
    },
    markAsHistory(groupId) {
      api.markTplGroupAsHistory(groupId).then(() => this.init());
    },
    onDetailData(t) {
      this.$router.push('/m/question/tpl/editor/' + t.id);
    },
    deleteTplGroup() {
      api.deleteTplGroup(this.groupId).then(() => {
        this.groupId = 0;
        this.init();
      });
    },
    onChoose(gid) {
      this.groupId = gid;
    },
    init() {
      api.getQuestionTplGroupList().then(ret => {
        this.historyGroups = [];
        this.groups = [];
        for (let g of ret) {
          if (g.extra) {
            this.historyGroups.push(g);
          } else {
            this.groups.push(g);
          }
        }
      });
    }
  },
  mounted() {
    this.init();
  }
};