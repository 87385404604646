import AnswerButtonGroup from "@/components/train/AnswerButtonGroup";
import CircleInTriangle from "@/components/subject/E808APlus/CircleInTriangle";
export default {
  name: "E808ARender",
  components: {
    CircleInTriangle,
    AnswerButtonGroup
  },
  props: {
    difficulty: {
      type: Object,
      default: () => {
        return {
          flashInterval: 3
        };
      }
    },
    review: {
      type: Object,
      default: () => {
        return null;
      }
    },
    reviewMode: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {
        return null;
      }
    }
  },
  watch: {
    data() {
      this.init();
    }
  },
  data() {
    return {
      timeout: null,
      visible: 0,
      letters: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
    };
  },
  methods: {
    onChoosed(l) {
      this.$emit('answer', l && l.length > 0 ? l.join(',') : null);
    },
    init() {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
      this.visible = 0;
      this.$refs.answerBtns.init();
    },
    showExample() {
      this.visible++;
      this.timeout = setTimeout(() => {
        this.visible++;
      }, this.difficulty.flashInterval * 1000);
    }
  },
  mounted() {
    this.init();
  }
};