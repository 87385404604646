import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_dialog = _resolveComponent("el-dialog");
  return _openBlock(), _createBlock(_component_el_dialog, {
    modelValue: $data.dialogVisible,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.dialogVisible = $event),
    width: $props.width,
    title: $props.title,
    onClose: $options.onDialogClose,
    "show-close": false
  }, {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "dialog", {}, undefined, true), _renderSlot(_ctx.$slots, "default", {}, undefined, true)]),
    _: 3
  }, 8, ["modelValue", "width", "title", "onClose"]);
}