import "core-js/modules/es.array.push.js";
import BatterTableDataView from "@/components/BatterTableDataView";
import api from "@/api";
export default {
  name: "HomeWorkView",
  components: {
    BatterTableDataView
  },
  methods: {
    reviewHw(workId) {
      api.startTplWorkById(workId).then(ret => {
        this.$router.push(`/train/${ret.subject}/${ret.examId}`);
      });
    },
    startHw(workId) {
      api.startTplWorkById(workId).then(ret => {
        this.$router.push(`/train/${ret.subject}/${ret.examId}`);
      });
    },
    init() {}
  },
  mounted() {
    this.init();
  }
};