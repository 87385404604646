import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-b65a22cc"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "ButtonDialogContainer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button");
  const _component_DialogView = _resolveComponent("DialogView");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [$props.style == 0 ? (_openBlock(), _createBlock(_component_el_button, {
    key: 0,
    type: "primary",
    size: $props.size,
    onClick: _cache[0] || (_cache[0] = $event => $data.btnVisible = true)
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString($props.btnName), 1)]),
    _: 1
  }, 8, ["size"])) : (_openBlock(), _createElementBlock("span", {
    key: 1,
    style: {
      "color": "#165DFF"
    },
    class: _normalizeClass({
      'ptr ft14': $props.size == 'small',
      'ptr ft16': $props.size == 'normal'
    }),
    onClick: _cache[1] || (_cache[1] = $event => $data.btnVisible = true)
  }, _toDisplayString($props.btnName), 3))]), _createVNode(_component_DialogView, {
    "data-id": $data.dialogId,
    "bind-id": $props.bindId,
    onSubmit: $options.onSubmit,
    onClose: _cache[2] || (_cache[2] = $event => $data.btnVisible = false),
    "visible-control": $data.btnVisible
  }, null, 8, ["data-id", "bind-id", "onSubmit", "visible-control"])], 64);
}