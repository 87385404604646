import DialogView from "@/components/DialogView";
import path from "@/api/path";
import api from "@/api";
import { ElLoading } from "element-plus";
export default {
  name: "ButtonDialog",
  components: {
    DialogView
  },
  props: {
    style: {
      type: Number,
      default: 0
    },
    btnId: {
      type: Number,
      default: 0
    },
    bindId: {
      type: Number,
      default: null
    },
    btnName: {
      type: String,
      default: '新增'
    },
    size: {
      type: String,
      default: 'normal'
    },
    initData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      dialogId: 0,
      submitUrl: '',
      btnVisible: false
    };
  },
  methods: {
    onSubmit(d) {
      let loading = ElLoading.service({
        lock: true,
        text: '请稍等...',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      for (let k of Object.keys(this.initData)) {
        d[k] = this.initData[k];
      }
      api.post(this.submitUrl, d).then(() => {
        this.btnVisible = false;
        this.$emit('fresh');
      }).finally(() => {
        loading.close();
      });
    },
    init() {
      this.dialogId = path.simpleBtn[this.btnId].dialogId;
      this.submitUrl = path.simpleBtn[this.btnId].submitUrl;
    }
  },
  mounted() {
    this.init();
  }
};