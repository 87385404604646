import "core-js/modules/es.regexp.flags.js";
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-616bdd52"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "sqr100",
  style: {
    "position": "relative"
  }
};
const _hoisted_2 = {
  width: "100",
  height: "100",
  xmlns: "http://www.w3.org/2000/svg"
};
const _hoisted_3 = ["stroke"];
const _hoisted_4 = ["stroke"];
const _hoisted_5 = ["stroke"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(), _createElementBlock("svg", _hoisted_2, [_createElementVNode("line", {
    x1: "50",
    y1: "10",
    x2: "5",
    y2: "90",
    stroke: $data.color[$props.colors.charAt(0)],
    "stroke-width": "3"
  }, null, 8, _hoisted_3), _createElementVNode("line", {
    x1: "5",
    y1: "90",
    x2: "95",
    y2: "90",
    stroke: $data.color[$props.colors.charAt(1)],
    "stroke-width": "3"
  }, null, 8, _hoisted_4), _createElementVNode("line", {
    x1: "95",
    y1: "90",
    x2: "50",
    y2: "10",
    stroke: $data.color[$props.colors.charAt(2)],
    "stroke-width": "3"
  }, null, 8, _hoisted_5)])), _createElementVNode("span", {
    style: _normalizeStyle({
      position: 'absolute',
      top: '13px',
      left: '45px',
      'color': $data.color[$props.colors.charAt(3)]
    }),
    class: _normalizeClass({
      'ft20': $props.colors.charAt(6) != 'Z',
      'ft20 rotateText': $props.colors.charAt(6) == 'Z'
    })
  }, _toDisplayString($data.flags[$props.colors.charAt([6])]), 7), _createElementVNode("span", {
    style: _normalizeStyle({
      position: 'absolute',
      bottom: '6px',
      left: '14px',
      'color': $data.color[$props.colors.charAt(4)]
    }),
    class: _normalizeClass({
      'ft20': $props.colors.charAt(7) != 'Z',
      'ft20 rotateText': $props.colors.charAt(7) == 'Z'
    })
  }, _toDisplayString($data.flags[$props.colors.charAt([7])]), 7), _createElementVNode("span", {
    style: _normalizeStyle({
      position: 'absolute',
      bottom: '6px',
      right: '14px',
      'color': $data.color[$props.colors.charAt(5)]
    }),
    class: _normalizeClass({
      'ft20': $props.colors.charAt(8) != 'Z',
      'ft20 rotateText': $props.colors.charAt(8) == 'Z'
    })
  }, _toDisplayString($data.flags[$props.colors.charAt([8])]), 7)]);
}