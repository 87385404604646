import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5c93409a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "DoubleChoiceBtnGroupContainer"
};
const _hoisted_2 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_row = _resolveComponent("el-row");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, {
    class: "bothCenter"
  }, {
    default: _withCtx(() => [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(4, op => {
      return _createVNode(_component_el_col, {
        span: 12,
        class: "bothCenter mt8",
        key: 'op_' + op
      }, {
        default: _withCtx(() => [_createElementVNode("span", {
          class: _normalizeClass({
            'ptr active': $data.values[op - 1],
            'ptr': !$data.values[op - 1]
          }),
          onClick: $event => $options.click(op - 1)
        }, "[" + _toDisplayString(op - 1) + "]", 11, _hoisted_2)]),
        _: 2
      }, 1024);
    }), 64))]),
    _: 1
  })]);
}