export default {
  name: "QuickScaleAnswerItem",
  props: {
    review: {
      type: Boolean,
      default: null
    },
    checked: {
      type: Boolean,
      default: null
    }
  },
  data() {
    return {
      selected: false
    };
  },
  watch: {
    review() {
      this.init();
    }
  },
  methods: {
    onClick() {
      if (this.review != null) {
        return;
      }
      this.selected = !this.selected;
      this.$emit('answer', this.selected ? 1 : 0);
    },
    init() {
      if (this.review != null) {
        this.selected = this.review;
      }
    }
  },
  mounted() {
    this.init();
  }
};