import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-07aae844"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "HomeWorkViewContainer wd100"
};
const _hoisted_2 = {
  key: 0
};
const _hoisted_3 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button");
  const _component_BatterTableDataView = _resolveComponent("BatterTableDataView");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_BatterTableDataView, {
    "table-id": 6
  }, {
    tpl: _withCtx(data => [data.rowData ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(data.rowData.tplName) + " - " + _toDisplayString(data.mapping[data.rowData.subject]), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_3, "-"))]),
    room: _withCtx(data => [_createElementVNode("span", null, _toDisplayString(data.rowData?.teacherName), 1)]),
    examId: _withCtx(data => [data.rowData ? (_openBlock(), _createBlock(_component_el_button, {
      key: 0,
      type: "success",
      size: "small",
      onClick: $event => $options.reviewHw(data.rowId)
    }, {
      default: _withCtx(() => [_createTextVNode("回看结果")]),
      _: 2
    }, 1032, ["onClick"])) : (_openBlock(), _createBlock(_component_el_button, {
      key: 1,
      type: "primary",
      size: "small",
      onClick: $event => $options.startHw(data.rowId)
    }, {
      default: _withCtx(() => [_createTextVNode("前往答题")]),
      _: 2
    }, 1032, ["onClick"]))]),
    _: 1
  })]);
}