// import AnswerButtonGroup from "@/components/train/AnswerButtonGroup";
// import CubeItem from '../Cube/CubeComponent';
// import CubeDemo from '../Cube/CubeDemo'

export default {
  name: "GroundPerspectiveRender",
  // components: { CubeDemo },
  props: {
    review: {
      type: Object,
      default: () => {
        return null;
      }
    },
    reviewMode: {
      type: Boolean,
      default: false
    },
    runtimeParams: {
      type: Array,
      default: () => []
    },
    data: {
      type: Object,
      default: () => {
        return null;
      }
    }
  },
  watch: {
    data() {
      this.init();
    }
  },
  data() {
    return {
      answer: null,
      select: [],
      positions: [{
        x: 0,
        y: 0,
        z: 0
      }, {
        x: 0,
        y: 0,
        z: 1
      }, {
        x: 0,
        y: 0,
        z: 2
      }]
    };
  },
  methods: {
    init() {
      this.answer = null;
      this.select = [];
    },
    onChoosed(l) {
      this.select = l;
      this.$emit('answer', l);
    }
  },
  mounted() {}
};