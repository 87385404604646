export default {
  name: "PriceCalculateLoad",
  props: {
    data: {
      type: String,
      default: 'A'
    }
  },
  data() {
    return {
      mapping: {
        'A': 'https://cdn.waityou.online/44486212-cac0-50b4-304b-a0d5366e0e8b.svg',
        'B': 'https://cdn.waityou.online/04148ad2-4d9d-ce12-55c3-ee00b5c2a276.svg',
        'C': 'https://cdn.waityou.online/8300761c-123b-a222-71ac-0f037359786a.svg',
        'D': 'https://cdn.waityou.online/6f895d15-343c-22f2-39fa-1383ce430e45.svg'
      }
    };
  },
  methods: {
    init() {}
  },
  mounted() {
    this.init();
  }
};