import api from "@/api";
import QuestionReviewRenderPage from "@/components/train/QuestionReviewRenderPage";
import subjects from "@/utils/subjects";
import LogicalQuestionEditor from "@/components/editor/LogicalQuestionEditor";
import SeesawQuestionEditor from "@/components/editor/SeesawQuestionEditor";
import PriceCalculateQuestionEditor from "@/components/editor/PriceCalculateQuestionEditor.vue";
export default {
  name: "QuestionTplEditorView",
  components: {
    PriceCalculateQuestionEditor,
    SeesawQuestionEditor,
    LogicalQuestionEditor,
    QuestionReviewRenderPage
  },
  data() {
    return {
      tplId: this.$route.params.tplId,
      subject: null,
      tpl: {},
      preview: null
    };
  },
  methods: {
    reGenerate(from, to) {
      api.reGenerateQuestionTpl({
        tplId: this.tplId,
        'from': from,
        'to': to
      }).then(() => {
        this.init();
      });
    },
    ansTypeMap(s) {
      return subjects.ansTypeMap[s];
    },
    init() {
      api.getQuestionTplInfo(this.tplId).then(ret => {
        this.tpl = ret;
        console.log(ret.subject);
        this.subject = ret.subject;
      });
      api.previewTplQuestion(this.tplId).then(ret => {
        this.preview = ret;
      });
    }
  },
  mounted() {
    this.init();
  }
};