import axios from "../utils/http";
import path from "./path"
const VERSION = 20240617;
const stringFormat = (formatted, args) => {
  for (let i = 0; i < args.length; i++) {
    let regexp = new RegExp('\\{' + i + '\\}', 'gi')
    formatted = formatted.replace(regexp, args[i])
  }
  return formatted
}
const api = {
  getOssKeySecret() {
    return axios.get(path.baseUrl + path.getOssKeySecret)
  },
  getQueryForm(formId, query) {
    return axios.get(path.baseUrl + path.queryForm[formId], { params: query })
  },
  get(url, query) {
    if (query) {
      return axios.get(path.baseUrl + url, { params: query })
    }
    return axios.get(path.baseUrl + url)
  },
  post(url, data) {
    return axios.post(path.baseUrl + url, data)
  },
  getDialogForm(dataId, bindId) {
    if (bindId != null) {
      return axios.get(path.baseUrl + stringFormat(path.dialogs[dataId], [bindId]))
    }
    return axios.get(path.baseUrl + path.dialogs[dataId])
  },
  getMenus() {
    return axios.get(path.baseUrl + path.menus)
  },
  getUserInfo() {
    return axios.get(path.baseUrl + path.userInfo)
  },
  getLoginCaptchaToken() {
    return axios.get(path.baseUrl + path.loginCaptchaToken)
  },
  login(data) {
    return axios.post(path.baseUrl + path.login, data)
  },
  logout() {
    return axios.get(path.baseUrl + path.logout)
  },
  updateSystemConfig(data) {
    return axios.post(path.baseUrl + path.updateSystemConfig, data);
  },
  getSystemConfig() {
    return axios.get(path.baseUrl + path.getSystemConfig)
  },
  updateImageValue(data) {
    return axios.post(path.baseUrl + path.updateImageValue, data);
  },
  getEnumOptionList(enumIndex) {
    return axios.get(path.baseUrl + stringFormat(path.getEnumOptionList, [enumIndex]))
  },
  startPractice(subject) {
    return axios.get(path.baseUrl + stringFormat(path.startPractice, [subject]))
  },
  queryCurrentQuestion(examId) {
    return axios.get(path.baseUrl + stringFormat(path.queryCurrentQuestion, [examId]))
  },
  submitAnswer(query) {
    query.version = VERSION;
    return axios.get(path.baseUrl + path.submitAnswer, { params: query })
  },
  queryHistoryExamResult(examId) {
    return axios.get(path.baseUrl + stringFormat(path.queryHistoryExamResult, [examId]))
  },
  batchSubmitAnswer(body) {
    body.version = VERSION;
    return axios.post(path.baseUrl + path.batchSubmitAnswer, body);
  },
  getUserGroupList() {
    return axios.get(path.baseUrl + path.getUserGroupList)
  },
  deleteUser(userId) {
    return axios.get(path.baseUrl + stringFormat(path.deleteUser, [userId]))
  },
  deleteGroup(groupId,keepUser) {
    return axios.get(path.baseUrl + stringFormat(path.deleteGroup, [groupId,keepUser]))
  },
  getLogicalGroups() {
    return axios.get(path.baseUrl + path.getLogicalGroups)
  },
  getLogicalQuestionByGroupId(groupId) {
    return axios.get(path.baseUrl + stringFormat(path.getLogicalQuestionByGroupId, [groupId]))
  },
  getUnfolding3DQuestionByGroupId(groupId) {
    return axios.get(path.baseUrl + stringFormat(path.getUnfolding3DQuestionByGroupId, [groupId]))
  },
  getLogicalQuestionTypeMap() {
    return axios.get(path.baseUrl + path.getLogicalQuestionTypeMap)
  },
  deleteLogicalQuestion(qid) {
    return axios.get(path.baseUrl + stringFormat(path.deleteLogicalQuestion, [qid]))
  },
  deleteUnfolding3dQuestion(qid) {
    return axios.get(path.baseUrl + stringFormat(path.deleteUnfolding3dQuestion, [qid]))
  },
  deleteLogicalGroup(groupId) {
    return axios.get(path.baseUrl + stringFormat(path.deleteLogicalGroup, [groupId]))
  },
  deleteUnfolding3dGroup(groupId) {
    return axios.get(path.baseUrl + stringFormat(path.deleteUnfolding3dGroup, [groupId]))
  },
  getQuestionTplGroupList() {
    return axios.get(path.baseUrl + path.getQuestionTplGroupList)
  },
  getUnfolding3DQuestionGroups() {
    return axios.get(path.baseUrl + path.getUnfolding3DQuestionGroups)
  },
  deleteTplGroup(groupId) {
    return axios.get(path.baseUrl + stringFormat(path.deleteTplGroup, [groupId]))
  },
  getQuestionTplInfo(tplId) {
    return axios.get(path.baseUrl + stringFormat(path.getQuestionTplInfo, [tplId]))
  },
  previewTplQuestion(tplId) {
    return axios.get(path.baseUrl + stringFormat(path.previewTplQuestion, [tplId]))
  },
  reGenerateQuestionTpl(query) {
    return axios.get(path.baseUrl + path.reGenerateQuestionTpl, { params: query })
  },
  updateTplQuestionId(query) {
    return axios.get(path.baseUrl + path.updateTplQuestionId, { params: query })
  },
  getTeachPlanInfo(planId) {
    return axios.get(path.baseUrl + stringFormat(path.getTeachPlanInfo, [planId]))
  },
  addTpl2ClassPlan(query) {
    return axios.get(path.baseUrl + path.addTpl2ClassPlan, { params: query })
  },
  deleteTplFromPlan(query) {
    return axios.get(path.baseUrl + path.deleteTplFromPlan, { params: query })
  },
  getClassRoomInfo(roomId) {
    return axios.get(path.baseUrl + stringFormat(path.getClassRoomInfo, [roomId]))
  },
  enableClassTpl(query) {
    return axios.get(path.baseUrl + path.enableClassTpl, { params: query })
  },
  queryClassRoom() {
    return axios.get(path.baseUrl + path.queryClassRoom)
  },
  getActiveTplList(roomId) {
    return axios.get(path.baseUrl + stringFormat(path.getActiveTplList, [roomId]))
  },
  startTplWork(query) {
    return axios.get(path.baseUrl + path.startTplWork, { params: query })
  },
  startTplWorkById(workId) {
    return axios.get(path.baseUrl + stringFormat(path.startTplWorkById, [workId]))
  },
  updateTplRuntimeParams(data) {
    return axios.post(path.baseUrl + path.updateTplRuntimeParams, data);
  },
  getClassAnswerStatistic(query) {
    return axios.get(path.baseUrl + path.getClassAnswerStatistic, { params: query })
  },
  getSeesawGroups() {
    return axios.get(path.baseUrl + path.getSeesawGroups)
  },
  getSeesawByGroupId(groupId) {
    return axios.get(path.baseUrl + stringFormat(path.getSeesawByGroupId, [groupId]))
  },
  deleteSeesawQuestion(qid) {
    return axios.get(path.baseUrl + stringFormat(path.deleteSeesawQuestion, [qid]))
  },
  deleteSeesawGroup(gid) {
    return axios.get(path.baseUrl + stringFormat(path.deleteSeesawGroup, [gid]))
  },
  getPriceCalculateGroups() {
    return axios.get(path.baseUrl + path.getPriceCalculateGroups)
  },
  getPriceCalculateByGroupId(groupId) {
    return axios.get(path.baseUrl + stringFormat(path.getPriceCalculateByGroupId, [groupId]))
  },
  deletePriceCalculateQuestion(qid) {
    return axios.get(path.baseUrl + stringFormat(path.deletePriceCalculateQuestion, [qid]))
  },
  deletePriceCalculateGroup(gid) {
    return axios.get(path.baseUrl + stringFormat(path.deletePriceCalculateGroup, [gid]))
  },
  getUserMenu() {
    return axios.get(path.baseUrl + path.getUserMenu)
  },
  getDifficultySettings() {
    return axios.get(path.baseUrl + path.getDifficultySettings)
  },
  updateSubjectSetting(data) {
    return axios.post(path.baseUrl + path.updateSubjectSetting, data);
  },
  updateSystemSetting(data) {
    return axios.post(path.baseUrl + path.updateSystemSetting, data);
  },
  markTplGroupAsHistory(groupId) {
    return axios.get(path.baseUrl + stringFormat(path.markTplGroupAsHistory, [groupId]))
  },
  updateRandomPractice(tplId) {
    return axios.get(path.baseUrl + stringFormat(path.updateRandomPractice, [tplId]))
  },
  getServerTime() {
    return axios.get(path.baseUrl + path.getServerTime)
  }
}

export default api;
