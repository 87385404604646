import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5c9ac684"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "HamsterCardContainer"
};
const _hoisted_2 = {
  key: 0,
  src: "https://edudata.computingis.fun/dishu.png",
  class: "sqr80 shake"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: _normalizeClass(`bg${$props.index} brd6 pd12 bothCenter`),
    style: {
      "width": "120px",
      "height": "120px"
    }
  }, [$data.visible ? (_openBlock(), _createElementBlock("img", _hoisted_2)) : _createCommentVNode("", true)], 2)]);
}