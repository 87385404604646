import * as echarts from "echarts";
export default {
  name: "ScoreChart",
  props: {
    score: {
      type: Number,
      default: 0
    }
  },
  watch: {
    score() {
      this.render();
    }
  },
  methods: {
    render() {
      let option = {
        series: [{
          type: 'gauge',
          axisLine: {
            lineStyle: {
              width: 10,
              color: [[0.3, '#67e0e3'], [0.7, '#37a2da'], [1, '#fd666d']]
            }
          },
          pointer: {
            itemStyle: {
              color: 'auto'
            }
          },
          axisTick: {
            distance: -30,
            length: 8,
            lineStyle: {
              color: '#fff',
              width: 2
            }
          },
          splitLine: {
            distance: -30,
            length: 30,
            lineStyle: {
              color: '#fff',
              width: 4
            }
          },
          axisLabel: {
            color: 'inherit',
            distance: -20,
            fontSize: 14
          },
          detail: {
            valueAnimation: true,
            formatter: '{value}',
            color: 'inherit'
          },
          data: [{
            value: this.score.toFixed(0)
          }]
        }]
      };
      let pie = document.getElementById('scoreChart');
      let exist = echarts.getInstanceByDom(pie);
      if (exist) {
        console.log(this.id, '销毁已存在的');
        exist.dispose();
      }
      let chart = echarts.init(pie);
      chart.setOption(option);
    },
    init() {
      this.render();
    }
  },
  mounted() {
    this.init();
  }
};