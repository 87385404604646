import AnswerCard from "@/components/train/AnswerCard";
import QuestionRenderPage from "@/components/train/QuestionRenderPage";
import api from "@/api";
import subjects from "@/utils/subjects";
import WatermarkerView from "@/components/WatermarkerView";
export default {
  name: "TrainAnswerView",
  components: {
    QuestionRenderPage,
    AnswerCard,
    WatermarkerView
  },
  data() {
    return {
      current: 0,
      subject: this.$route.params.subject,
      examId: this.$route.params.examId,
      session: null
    };
  },
  methods: {
    ansTypeMap() {
      return subjects.ansTypeMap[this.subject];
    },
    ansModeMap() {
      return subjects.ansModeMap[this.subject];
    },
    init() {
      api.queryCurrentQuestion(this.examId).then(ret => {
        if (ret.historyMark) {
          this.$router.replace('/review/' + ret.subject + '/' + ret.examId);
          return;
        }
        this.current = ret.current;
        this.session = ret;
      }).catch(() => {
        this.$router.replace('/');
      });
    },
    onTimeUp() {
      this.$refs.questionRender.onTimeUp();
    }
  },
  mounted() {
    this.init();
  }
};