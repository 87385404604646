export default {
  name: "QuickScaleItem",
  props: {
    type: {
      type: Number,
      default: 0
    }
  },
  methods: {
    init() {}
  },
  mounted() {
    this.init();
  }
};