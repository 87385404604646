import "core-js/modules/es.array.push.js";
import BatterTableDataView from "@/components/BatterTableDataView";
export default {
  name: "TeachingPlanView",
  components: {
    BatterTableDataView
  },
  methods: {
    onDetailData(d) {
      this.$router.push('/m/teaching/plan/editor/' + d.id);
    },
    init() {}
  },
  mounted() {
    this.init();
  }
};