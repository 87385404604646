import E808D2Triangle from "@/components/subject/E808D2/E808D2Triangle";
import E808D2AnswerBtn from "@/components/subject/E808D2/E808D2AnswerBtn";
export default {
  name: "E808D2Render",
  components: {
    E808D2AnswerBtn,
    E808D2Triangle
  },
  props: {
    review: {
      type: Object,
      default: () => {
        return null;
      }
    },
    reviewMode: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default: () => {
        return null;
      }
    }
  },
  data() {
    return {
      ans: {}
    };
  },
  methods: {
    onSelectAnswer(qindex, val) {
      this.ans[qindex] = val;
      console.log('onSelectAnswer', this.ans);
      for (let q of this.data) {
        if (this.ans[q.questionIndex] == null) {
          this.$emit('answer', null);
          return;
        }
      }
      this.$emit('answer', this.ans);
    },
    init() {
      console.log('init', this.data);
      for (let q of this.data) {
        this.ans[q.questionIndex] = null;
      }
    }
  },
  mounted() {
    this.init();
  }
};