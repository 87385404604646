import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { Sky } from 'three/examples/jsm/objects/Sky.js';
import { nextTick } from "vue";
import api from "@/api";
import OSS from "ali-oss";
import base64ImgtoFile from "@/utils/tools";
import { ElMessage } from "element-plus";
import { BufferGeometryUtils } from 'three/examples/jsm/utils/BufferGeometryUtils.js';
export default {
  name: "IceCubeItem",
  props: {
    cubePositions: {
      type: Array,
      default: () => {
        return [];
      }
    },
    viewPoint: {
      type: Object,
      default: () => {
        return {
          x: 0,
          y: 0,
          z: 0
        };
      }
    },
    previewImages: {
      type: Object,
      default: () => {
        return {};
      }
    },
    localIndex: {
      type: Number,
      default: 0
    },
    isSvae: {
      type: Boolean,
      default: true
    },
    isDesc: {
      type: Boolean,
      default: false
    },
    subject: {
      type: String,
      default: '-'
    },
    size: {
      type: Object,
      default: () => {
        return {
          width: 200,
          height: 200
        };
      }
    }
  },
  data() {
    return {
      containerId: null,
      render: null,
      ossClient: null,
      ossDomain: null,
      ossFolder: null,
      exportRequested: false
    };
  },
  computed: {
    noBoxLine() {
      return ['-'].includes(this.subject);
    },
    blockSkin() {
      if (['BlockRotate', 'FillWall'].includes(this.subject)) {
        return '#f3c3be';
      }
      return 0xD6EAF8;
    },
    focusPointLight() {
      if (['FillWall'].includes(this.subject)) {
        return true;
      }
      return false;
    },
    boxLineColor() {
      if (['BlockRotate', 'FillWall'].includes(this.subject)) {
        return '#8166c3';
      }
      return 0x000000;
    },
    isBoldEdge() {
      if (['BlockRotate', 'FillWall', 'CubeComplement', 'RandomForest'].includes(this.subject)) {
        return true;
      }
      return false;
    }
  },
  methods: {
    guid() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
          v = c === 'x' ? r : r & 0x3 | 0x8;
        return v.toString(16);
      });
    },
    initOssClint() {
      api.getOssKeySecret().then(cfg => {
        this.ossClient = new OSS({
          region: cfg.region,
          accessKeyId: cfg.accessKeyId,
          accessKeySecret: cfg.accessKeySecret,
          stsToken: cfg.securityToken,
          bucket: cfg.bucket,
          endpoint: cfg.endpoint,
          secure: true
        });
        this.ossDomain = cfg.domain;
        this.ossFolder = cfg.folder;
      });
    },
    handleSaveImage() {
      this.exportRequested = true;
    },
    exportScene() {
      let thiz = this;
      let imgData = this.render.domElement.toDataURL('image/png');
      const img = base64ImgtoFile(imgData, new Date().getTime());
      const fileName = (this.ossFolder ? this.ossFolder + '/' : 'user-upload/') + this.guid() + '.png';
      this.ossClient.put(fileName, img).then(res => {
        if (res.res.statusCode === 200) {
          ElMessage.success('上传成功');
          const imgUrl = `https://sjxzc.oss-cn-beijing.aliyuncs.com/${fileName}`;
          thiz.$emit('onSaveImage', imgUrl, this.localIndex, this.isDesc);
          // props.onSaveImage(fileName);
        } else {
          ElMessage.info('上传失败');
        }
      });
    },
    initThreeJS() {
      const container = document.getElementById(this.containerId);
      const width = container.clientWidth;
      const height = container.clientHeight;

      // 场景
      const scene = new THREE.Scene();
      scene.background = new THREE.Color(0xffffff);

      // 相机
      const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
      camera.position.set(5, 5, 5);
      camera.lookAt(0, 0, 0);

      // 渲染器
      const renderer = new THREE.WebGLRenderer({
        antialias: true
      });
      this.render = renderer;
      renderer.setSize(width, height);
      renderer.shadowMap.enabled = true; // 启用阴影映射
      container.appendChild(renderer.domElement);

      // 添加环境光
      const ambientLight = new THREE.AmbientLight(0x404040, 20); // 软白光
      scene.add(ambientLight);

      // 添加点光源
      const pointLight = new THREE.PointLight(0xffffff, 300, 20);
      if (this.focusPointLight) {
        pointLight.position.set(2, 6, 5);
      } else {
        pointLight.position.set(0, 10, 0);
      }
      pointLight.castShadow = true; // 设置点光源产生阴影
      pointLight.shadow.mapSize.width = 256; // 阴影分辨率宽度
      pointLight.shadow.mapSize.height = 256; // 阴影分辨率高度
      pointLight.shadow.camera.near = 0.5; // 阴影相机近平面
      pointLight.shadow.camera.far = 500; // 阴影相机远平面
      scene.add(pointLight);

      // 观察点(如有)
      if (this.viewPoint) {
        const sphereSize = 0.2;
        const viewPointSphere = new THREE.Mesh(new THREE.SphereGeometry(sphereSize, 16, 8), new THREE.MeshBasicMaterial({
          color: 0xff0000
        }));
        viewPointSphere.position.set(this.viewPoint.x, this.viewPoint.y, this.viewPoint.z);
        scene.add(viewPointSphere);
      }

      // 创建正方体并添加到场景
      this.cubePositions.forEach(coord => {
        const geometry = new THREE.BoxGeometry();
        const material = new THREE.MeshPhongMaterial({
          color: this.blockSkin
        });
        const cube = new THREE.Mesh(geometry, material);
        cube.position.set(coord.x, coord.y, coord.z);
        cube.castShadow = true; // 设置正方体投射阴影
        cube.receiveShadow = true; // 设置正方体接收阴影
        scene.add(cube);
        if (!this.noBoxLine) {
          // 为正方体添加边框
          const edges = new THREE.EdgesGeometry(geometry);
          if (this.isBoldEdge) {
            //加粗边缘
            const edgesPoints = edges.attributes.position.array;
            for (let i = 0; i < edgesPoints.length; i += 6) {
              const start = new THREE.Vector3(edgesPoints[i], edgesPoints[i + 1], edgesPoints[i + 2]);
              const end = new THREE.Vector3(edgesPoints[i + 3], edgesPoints[i + 4], edgesPoints[i + 5]);
              const path = new THREE.LineCurve3(start, end);
              const tubeGeometry = new THREE.TubeGeometry(path, 20, 0.05, 8, false);
              const tubeMaterial = new THREE.MeshBasicMaterial({
                color: this.boxLineColor
              });
              const tube = new THREE.Mesh(tubeGeometry, tubeMaterial);
              cube.add(tube);
            }
          } else {
            const line = new THREE.LineSegments(edges, new THREE.LineBasicMaterial({
              color: this.boxLineColor
            }));
            cube.add(line);
          }
        }
      });

      // 控制器
      const controls = new OrbitControls(camera, renderer.domElement);
      controls.enableDamping = true;
      controls.dampingFactor = 0.1;
      controls.enableZoom = true;

      // 动画循环
      const animate = () => {
        requestAnimationFrame(animate);
        controls.update();
        renderer.render(scene, camera);
        // 检查是否需要导出场景
        if (this.exportRequested) {
          this.exportScene();
          this.exportRequested = false;
        }
      };
      animate();

      // 创建天空对象
      const sky = new Sky();
      sky.scale.setScalar(100);
      scene.add(sky);

      // 天空的参数，设置为正午晴天
      const skyParams = {
        turbidity: 0.1,
        // 浑浊度较低
        rayleigh: 0.2,
        // 较低的瑞利散射
        mieCoefficient: 0.005,
        // 米散射系数
        mieDirectionalG: 0.7,
        // 米散射方向因子
        elevation: 90,
        // 太阳在天顶，90度
        azimuth: 180,
        // 太阳方位角，180度为正南
        exposure: renderer.toneMappingExposure
      };

      // 更新天空的状态
      const updateSky = () => {
        const uniforms = sky.material.uniforms;
        uniforms['turbidity'].value = skyParams.turbidity;
        uniforms['rayleigh'].value = skyParams.rayleigh;
        uniforms['mieCoefficient'].value = skyParams.mieCoefficient;
        uniforms['mieDirectionalG'].value = skyParams.mieDirectionalG;
        const theta = Math.PI * (skyParams.elevation / 180);
        const phi = 2 * Math.PI * (skyParams.azimuth / 360) - Math.PI / 2;
        const sun = new THREE.Vector3();
        sun.x = Math.cos(phi);
        sun.y = Math.sin(phi) * Math.sin(theta);
        sun.z = Math.sin(phi) * Math.cos(theta);
        sky.material.uniforms['sunPosition'].value.copy(sun);
        renderer.toneMappingExposure = skyParams.exposure;
      };
      updateSky();

      // 创建地平面
      const planeGeometry = new THREE.PlaneGeometry(1000, 1000); // 设置地平面大小
      const planeMaterial = new THREE.MeshLambertMaterial({
        color: 0xAED6F1
      }); // 淡蓝色材质
      const plane = new THREE.Mesh(planeGeometry, planeMaterial);
      plane.rotation.x = -Math.PI / 2; // 将平面旋转以水平放置
      plane.position.y = -3; // 根据需要调整地平面的位置
      plane.receiveShadow = true; // 如果需要接收阴影
      scene.add(plane);
    },
    init() {
      this.initThreeJS();
    }
  },
  mounted() {
    this.containerId = this.guid();
    this.initOssClint();
    nextTick(() => {
      this.init();
    });
  }
};