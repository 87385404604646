import CirclePlusInTriangle from "@/components/subject/E808B/CirclePlusInTriangle";
import DoubleChoiceBtnGroup from "@/components/subject/E808B/DoubleChoiceBtnGroup";
export default {
  name: "E808BRender",
  components: {
    DoubleChoiceBtnGroup,
    CirclePlusInTriangle
  },
  props: {
    review: {
      type: Object,
      default: () => {
        return null;
      }
    },
    reviewMode: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default: () => {
        return null;
      }
    }
  },
  data() {
    return {
      ans: {}
    };
  },
  methods: {
    onSelectAnswer(qindex, val) {
      this.ans[qindex] = val;
      // console.log('onSelectAnswer',this.ans)
      for (let q of this.data) {
        if (this.ans[q.questionIndex] == null) {
          this.$emit('answer', null);
          return;
        }
      }
      this.$emit('answer', this.ans);
    },
    init() {
      console.log('init', this.data);
      for (let q of this.data) {
        this.ans[q.questionIndex] = null;
      }
    }
  },
  mounted() {
    this.init();
  }
};