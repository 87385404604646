import E808B2Triangle from "@/components/subject/E808B2/E808B2Triangle";
import E808B2BtnGroup from "@/components/subject/E808B2/E808B2BtnGroup";
export default {
  name: "E808B2Render",
  components: {
    E808B2Triangle,
    E808B2BtnGroup
  },
  props: {
    review: {
      type: Object,
      default: () => {
        return null;
      }
    },
    reviewMode: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default: () => {
        return null;
      }
    }
  },
  watch: {
    data() {
      this.init();
    }
  },
  data() {
    return {
      ans: {}
    };
  },
  methods: {
    onAnswer(qIndex, val) {
      this.ans[qIndex] = val;
      for (let q of this.data) {
        if (this.ans[q.questionIndex] == null) {
          this.$emit('answer', null);
          return;
        }
      }
      this.$emit('answer', this.ans);
    },
    init() {
      this.ans = {};
      let k = this.data[this.data.length - 1].questionIndex;
      this.ans[k] = '@@';
    }
  },
  mounted() {
    this.init();
  }
};