import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-c7302092"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  width: "100",
  height: "100",
  xmlns: "http://www.w3.org/2000/svg"
};
const _hoisted_2 = ["stroke"];
const _hoisted_3 = ["stroke"];
const _hoisted_4 = ["stroke"];
const _hoisted_5 = ["stroke"];
const _hoisted_6 = ["stroke"];
const _hoisted_7 = ["stroke"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("svg", _hoisted_1, [_createElementVNode("path", {
    d: "M 50,10 A 40,40 0 0 1 82.32,67.68",
    fill: "none",
    stroke: $data.color[$props.colors.charAt(1)],
    "stroke-width": "3"
  }, null, 8, _hoisted_2), _createElementVNode("path", {
    d: "M 82.32,67.68 A 40,40 0 0 1 17.68,67.68",
    fill: "none",
    stroke: $data.color[$props.colors.charAt(2)],
    "stroke-width": "3"
  }, null, 8, _hoisted_3), _createElementVNode("path", {
    d: "M 17.68,67.68 A 40,40 0 0 1 50,10",
    fill: "none",
    stroke: $data.color[$props.colors.charAt(0)],
    "stroke-width": "3"
  }, null, 8, _hoisted_4), _createElementVNode("line", {
    x1: "82",
    y1: "68",
    x2: "50",
    y2: "50",
    stroke: $data.color[$props.colors.charAt(3)],
    "stroke-width": "3"
  }, null, 8, _hoisted_5), _createElementVNode("line", {
    x1: "17",
    y1: "67",
    x2: "50",
    y2: "50",
    stroke: $data.color[$props.colors.charAt(4)],
    "stroke-width": "3"
  }, null, 8, _hoisted_6), _createElementVNode("line", {
    x1: "50",
    y1: "11",
    x2: "50",
    y2: "50",
    stroke: $data.color[$props.colors.charAt(5)],
    "stroke-width": "3"
  }, null, 8, _hoisted_7)]);
}