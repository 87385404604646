import LeftGroupList from "@/components/LeftGroupList";
import api from "@/api";
import BatterTableDataView from "@/components/BatterTableDataView";
export default {
  name: "QuestionTplSelector",
  components: {
    BatterTableDataView,
    LeftGroupList
  },
  data() {
    return {
      groups: [],
      groupId: 0
    };
  },
  methods: {
    choose(tid) {
      this.$emit('select', tid);
    },
    onChoose(gid) {
      this.groupId = gid;
    },
    init() {
      api.getQuestionTplGroupList().then(ret => {
        this.groups = ret;
      });
    }
  },
  mounted() {
    this.init();
  }
};