import LeftGroupList from "@/components/LeftGroupList";
import api from "@/api";
import BatterTableDataView from "@/components/BatterTableDataView";
import ButtonDialog from "@/components/ButtonDialog";
export default {
  name: "UserManageView",
  components: {
    ButtonDialog,
    BatterTableDataView,
    LeftGroupList
  },
  data() {
    return {
      groups: [],
      groupId: 0,
      search: null
    };
  },
  methods: {
    deleteGroup(keepUser) {
      api.deleteGroup(this.groupId, keepUser).then(() => {
        this.groupId = 0;
        this.init();
      });
    },
    deleteUser(userId) {
      api.deleteUser(userId).then(() => {
        this.$refs.studentTable.fetchData();
      });
    },
    onChoose(gid) {
      this.groupId = gid;
    },
    init() {
      api.getUserGroupList().then(ret => {
        this.groups = ret;
      });
    }
  },
  mounted() {
    this.init();
  }
};