export default {
  name: "BlackWhiteDotGraph",
  components: {},
  props: {
    board: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {};
  },
  methods: {
    init() {}
  },
  mounted() {
    this.init();
  }
};