import AnswerButtonGroup from "@/components/train/AnswerButtonGroup";
import DialogCustomView from "@/components/DialogCustomView";
export default {
  name: "ImageTransformRender",
  components: {
    DialogCustomView,
    AnswerButtonGroup
  },
  props: {
    review: {
      type: Object,
      default: () => {
        return null;
      }
    },
    reviewMode: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {
        return null;
      }
    }
  },
  watch: {
    data() {
      this.init();
    }
  },
  data() {
    return {
      snapshotVisible: false,
      transform: {
        SwapNo1: "与第一个图形交换",
        SwapNo2: "与第二个图形交换",
        SwapNo3: "与第三个图形交换",
        SwapNo4: "与第四个图形交换",
        ReplaceAsNo1: "替换为第一个图形",
        ReplaceAsNo2: "替换为第二个图形",
        ReplaceAsNo3: "替换为第三个图形",
        ReplaceAsNo4: "替换为第四个图形",
        FlipHorizontal: "水平翻转",
        FlipVertical: "垂直翻转",
        CW90: "顺时针旋转90度",
        CW180: "顺时针旋转180度",
        CCW90: "逆时针旋转90度",
        ReversedPhaseAll: "图形内白色和黑色互换",
        BlackToWhite: "图形内黑色部分变白色",
        WhiteToBlack: "图形内白色部分变黑色"
      }
    };
  },
  methods: {
    showSnapshot() {
      this.snapshotVisible = true;
    },
    onChoosed(l) {
      this.$emit('answer', l && l.length > 0 ? l[0] : null);
    },
    init() {
      this.$refs.answerBtns.init();
    }
  },
  mounted() {
    this.init();
  }
};