import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-18dd9099"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "bothCenter colFlex opd6 mb16"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      'OrderedAnswerButtonGroupContainer bothCenter': $props.optionBothCenter,
      'OrderedAnswerButtonGroupContainer toBottom': !$props.optionBothCenter
    })
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.options, (op, opIndex) => {
    return _openBlock(), _createElementBlock("div", {
      key: op,
      class: "bothCenter colFlex opd6 mb16"
    }, [_createVNode(_component_el_button, {
      onClick: $event => $options.onClick(op),
      disabled: $props.reviewMode,
      style: _normalizeStyle({
        width: $props.optionWidth
      })
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString($data.letters[opIndex]), 1)]),
      _: 2
    }, 1032, ["onClick", "disabled", "style"])]);
  }), 128)), _createElementVNode("div", _hoisted_1, [_createVNode(_component_el_button, {
    onClick: $options.deleteLast,
    disabled: $props.reviewMode,
    type: "danger",
    style: _normalizeStyle({
      width: $props.optionWidth
    })
  }, {
    default: _withCtx(() => [_createTextVNode("删除 ")]),
    _: 1
  }, 8, ["onClick", "disabled", "style"])])], 2);
}