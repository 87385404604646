export default {
  name: "E808D2AnswerBtn",
  props: {
    review: {
      type: Object,
      default: () => {
        return null;
      }
    },
    answer: {
      type: String,
      default: null
    },
    data: {
      type: String,
      default: "RGBRGBRXYZ"
    }
  },
  watch: {
    review() {
      this.init();
    }
  },
  data() {
    return {
      ans: [-1, '?']
    };
  },
  methods: {
    ans0(v) {
      if (this.answer != null) {
        return;
      }
      this.ans[0] = v;
      this.returnAnswer();
    },
    ans1(v) {
      if (this.answer != null) {
        return;
      }
      this.ans[1] = v;
      this.returnAnswer();
    },
    returnAnswer() {
      if (this.ans[0] > -1 && this.ans[1] != '?') {
        this.$emit('answer', `${this.ans[0]}${this.ans[1]}`);
      }
    },
    init() {
      if (this.review != null) {
        this.ans[0] = this.review.answer[0];
        this.ans[1] = this.review.answer[1];
      }
    }
  },
  mounted() {
    this.init();
  }
};