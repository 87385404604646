import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  width: "100",
  height: "100",
  xmlns: "http://www.w3.org/2000/svg"
};
const _hoisted_2 = ["stroke"];
const _hoisted_3 = ["stroke"];
const _hoisted_4 = ["stroke"];
const _hoisted_5 = ["stroke"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("svg", _hoisted_1, [_createElementVNode("line", {
    x1: "50",
    y1: "10",
    x2: "5",
    y2: "90",
    stroke: $data.color[$props.colors.charAt(0)],
    "stroke-width": "3"
  }, null, 8, _hoisted_2), _createElementVNode("line", {
    x1: "5",
    y1: "90",
    x2: "95",
    y2: "90",
    stroke: $data.color[$props.colors.charAt(2)],
    "stroke-width": "3"
  }, null, 8, _hoisted_3), _createElementVNode("line", {
    x1: "95",
    y1: "90",
    x2: "50",
    y2: "10",
    stroke: $data.color[$props.colors.charAt(1)],
    "stroke-width": "3"
  }, null, 8, _hoisted_4), _createElementVNode("circle", {
    cx: "50",
    cy: "63",
    r: "15",
    stroke: $data.color[$props.colors.charAt(3)],
    "stroke-width": "3",
    fill: "none"
  }, null, 8, _hoisted_5)]);
}