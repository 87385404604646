import api from "@/api";
import { ElMessage } from "element-plus";
export default {
  name: "DifficultySettings",
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    },
    type: {
      type: String,
      default: 'PRACTICE'
    }
  },
  data() {
    return {
      settings: {
        FlashDiamond: {
          flashInterval: 1
        },
        BlackWhiteDot: {
          flashInterval: 1
        },
        MossCopy: {
          flashInterval: 1
        },
        MossMissing4: {
          flashInterval: 1
        },
        MossMissing5: {
          flashInterval: 1
        },
        E808A: {
          flashInterval: 1
        },
        E808E: {
          flashInterval: 1
        },
        FastMemory: {
          flashInterval: 1
        },
        WhackAMole: {
          playInterval: 1
        }
      }
    };
  },
  methods: {
    updateSetting(subject) {
      api.updateSubjectSetting({
        'subject': subject,
        'setting': this.settings[subject],
        'type': this.type
      }).then(() => {
        ElMessage({
          message: '配置已保存',
          type: 'success'
        });
      });
    },
    init() {
      for (let k of Object.keys(this.data)) {
        this.settings[k] = this.data[k];
      }
    }
  },
  mounted() {
    this.init();
  }
};