export default {
  name: "SeesawLoad",
  props: {
    data: {
      type: String,
      default: 'A'
    }
  },
  data() {
    return {
      mapping: {
        'A': 'https://cdn.waityou.online/3fa55dfb-7f16-80da-be09-3ac50dd75760.svg',
        'B': 'https://cdn.waityou.online/36c205c4-dfcf-01ed-7d4a-38f97fc709c3.svg',
        'C': 'https://cdn.waityou.online/58b7e4f2-e4a2-6304-eab8-81fd6c8ad463.svg',
        'D': 'https://cdn.waityou.online/af25f1b5-054c-9fa1-74a3-a9033817892b.svg'
      }
    };
  },
  methods: {
    init() {}
  },
  mounted() {
    this.init();
  }
};