import api from "@/api";
export default {
  name: "SubjectCard",
  props: {
    index: {
      type: Number,
      default: 0
    },
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      btnVisible: false,
      bgSrc: ['https://cdn.waityou.online/2c62ca3d-a0d9-044f-d186-1e7b1b4795d5.png', 'https://cdn.waityou.online/f7cbc832-6a83-546d-8e1e-82dcdb77d4f6.png', 'https://cdn.waityou.online/96aaa820-9d3e-400a-778e-cca3a51be50e.png', 'https://cdn.waityou.online/4e5a7ef1-dabf-71fe-10b4-dd51475080dc.png']
    };
  },
  methods: {
    start() {
      api.startPractice(this.data.subject).then(ret => {
        window.open(`/#/train/${this.data.subject}/${ret.examId}`, '_blank');
        // this.$router.push(`/train/${this.data.subject}/${ret.examId}`)
      });
    },

    init() {}
  },
  mounted() {
    this.init();
  }
};