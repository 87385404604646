import { createRouter, createWebHashHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import HomeView from "@/views/HomeView";
import DashboardView from "@/views/DashboardView";
import TrainAnswerView from "@/views/TrainAnswerView";
import ReviewAnswerView from "@/views/ReviewAnswerView";
import UserManageView from "@/views/manager/UserManageView";
import ManagerView from "@/views/ManagerView";
import LogicalQuestionView from "@/views/manager/LogicalQuestionView";
import QuestionTplView from "@/views/manager/QuestionTplView";
import TeachingPlanView from "@/views/manager/TeachingPlanView";
import TeachingClassView from "@/views/manager/TeachingClassView";
import QuestionTplEditorView from "@/views/manager/QuestionTplEditorView";
import TeachingPlanEditorView from "@/views/manager/TeachingPlanEditorView";
import ClassRoomDetailView from "@/views/manager/ClassRoomDetailView";
import ClassRoomAnswerDetailView from "@/views/manager/ClassRoomAnswerDetailView";
import MyClassRoomView from "@/views/MyClassRoomView";
import AnswerHistoryView from "@/views/AnswerHistoryView";
import HomeWorkView from "@/views/HomeWorkView";
import SeesawQuestionView from "@/views/manager/SeesawQuestionView";
import SystemSettingView from "@/views/manager/SystemSettingView";
import ClassWorkView from "@/views/ClassWorkView.vue";
import PriceCalculateQuestionView from "@/views/manager/PriceCalculateQuestionView.vue";
import Unfolding3DView from "@/views/manager/Unfolding3DView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    redirect: "/dashboard",
    children: [
      {
        path: "/dashboard",
        component: DashboardView
      }, {
        path: "/class/room",
        component: MyClassRoomView
      }, {
        path: "/answer/history",
        component: AnswerHistoryView
      }, {
        path: "/home/work",
        component: HomeWorkView
      },{
        path: "/class/work",
        component: ClassWorkView
      },
    ],
    meta: {
      auth: true
    }
  },
  {
    path: "/m",
    name: "manager",
    component: ManagerView,
    redirect: "/m/user/list",
    children: [
      {
        path: "/m/user/list",
        component: UserManageView
      },
      {
        path: "/m/logical/list",
        component: LogicalQuestionView
      },
      {
        path: "/m/unfolding3d/list",
        component: Unfolding3DView
      },
      {
        path: "/m/seesaw/list",
        component: SeesawQuestionView
      },
      {
        path: "/m/price/calculate/list",
        component: PriceCalculateQuestionView
      },
      {
        path: "/m/question/tpl/list",
        component: QuestionTplView
      },
      {
        path: "/m/teaching/plan/list",
        component: TeachingPlanView
      },
      {
        path: "/m/teaching/plan/editor/:planId",
        component: TeachingPlanEditorView
      },
      {
        path: "/m/teaching/class",
        component: TeachingClassView
      },
      {
        path: "/m/teaching/class/room/:roomId",
        component: ClassRoomDetailView
      },
      {
        path: "/m/teaching/class/answer/:roomId/:tplId/:state",
        component: ClassRoomAnswerDetailView
      },
      {
        path: "/m/question/tpl/editor/:tplId",
        component: QuestionTplEditorView
      },
      {
        path: "/m/setting",
        component: SystemSettingView
      },
    ],
    meta: {
      auth: true
    }
  },
  {
    path: '/train/:subject/:examId',
    component: TrainAnswerView
  },
  {
    path: '/review/:subject/:examId',
    component: ReviewAnswerView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      auth: false
    }
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
