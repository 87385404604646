export default {
  name: "E808ETriangle",
  props: {
    colors: {
      type: String,
      default: 'RGBBGR'
    }
  },
  data() {
    return {
      color: {
        'R': 'red',
        'G': 'green',
        'B': 'blue',
        'X': 'black'
      }
    };
  },
  methods: {
    init() {}
  },
  mounted() {
    this.init();
  }
};