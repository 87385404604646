import api from "@/api";
import DifficultySettings from "@/components/setting/DifficultySettings";
import { ElMessage } from "element-plus";
export default {
  name: "SystemSettingView",
  components: {
    DifficultySettings
  },
  data() {
    return {
      activeName: 'system',
      practice: null,
      onclass: null,
      system: {
        DEFAULT_EXAM_HISTORY_REVIEW: 7
      }
    };
  },
  methods: {
    updateSetting(k) {
      api.updateSystemSetting({
        'key': k,
        'value': this.system[k]
      }).then(() => {
        ElMessage({
          message: '配置已保存',
          type: 'success'
        });
      });
    },
    init() {
      api.getSystemConfig().then(ret => {
        this.system = ret;
      });
      api.getDifficultySettings().then(ret => {
        this.practice = ret.practice;
        this.onclass = ret.onclass;
      });
    }
  },
  mounted() {
    this.init();
  }
};