import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      'AnswerButtonGroupContainer bothCenter': $props.optionBothCenter,
      'AnswerButtonGroupContainer toBottom': !$props.optionBothCenter
    })
  }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.options, (op, opIndex) => {
    return _openBlock(), _createElementBlock("div", {
      key: op,
      class: "bothCenter colFlex opd6 mb16"
    }, [_renderSlot(_ctx.$slots, "default", {
      index: opIndex
    }, undefined, true), _createVNode(_component_el_button, {
      onClick: $event => $options.onClick(op),
      disabled: $props.reviewMode,
      type: $data.selected[op] ? 'primary' : '',
      style: _normalizeStyle({
        width: $props.optionWidth
      })
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString($data.letters[opIndex]), 1)]),
      _: 2
    }, 1032, ["onClick", "disabled", "type", "style"])]);
  }), 128))], 2);
}