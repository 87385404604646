export default {
  name: "NineFlashingBoard",
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      flashing: {
        ReversedPhaseAll: "所有格子变为相反色",
        FlipHorizontal: "上部和下部互换",
        FlipVertical: "左部和右部互换",
        CW90: "顺时针旋转90度",
        CCW90: "逆时针旋转90度",
        AboveBlackToWhite: "上部分黑色变白色",
        BelowBlackToWhite: "下部分黑色变白色",
        LeftBlackToWhite: "左部分黑色变白色",
        RightBlackToWhite: "右部分黑色变白色",
        AboveWhiteToBlack: "上部分白色变黑色",
        BelowWhiteToBlack: "下部分白色变黑色",
        LeftWhiteToBlack: "左部分白色变黑色",
        RightWhiteToBlack: "右部分白色变黑色",
        ReversedPhase0: "第1格变为相反色",
        ReversedPhase1: "第2格变为相反色",
        ReversedPhase2: "第3格变为相反色",
        ReversedPhase3: "第4格变为相反色"
      }
    };
  },
  methods: {
    init() {}
  },
  mounted() {
    this.init();
  }
};