import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-30ab00e0"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "QuickScaleAnswerItemContainer colFlex bothCenter"
};
const _hoisted_2 = {
  style: {
    "height": "20px"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Select = _resolveComponent("Select");
  const _component_el_icon = _resolveComponent("el-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    style: _normalizeStyle({
      width: '10px',
      height: '20px',
      border: '1px solid #1388ff',
      background: $data.selected ? '#1388ff' : 'white'
    }),
    onClick: _cache[0] || (_cache[0] = (...args) => $options.onClick && $options.onClick(...args)),
    class: "ptr"
  }, null, 4), _createElementVNode("div", _hoisted_2, [$props.checked ? (_openBlock(), _createBlock(_component_el_icon, {
    key: 0,
    color: "green"
  }, {
    default: _withCtx(() => [_createVNode(_component_Select)]),
    _: 1
  })) : _createCommentVNode("", true)])]);
}