import PriceCalculateLoad from "@/components/subject/PriceCalculate/PriceCalculateLoad.vue";
export default {
  name: "PriceCalculateImage",
  components: {
    PriceCalculateLoad
  },
  props: {
    data: {
      type: String,
      default: null
    }
  },
  watch: {
    data() {
      this.init();
    }
  },
  data() {
    return {
      flag: null,
      left: null,
      right: null
    };
  },
  methods: {
    init() {
      if (!this.data) {
        return;
      }
      let noBlank = this.data.replace(/\s+/g, "");
      if (noBlank.indexOf('=') > 0) {
        this.left = noBlank.split('=')[0];
        this.right = noBlank.split('=')[1].split('+');
      }
    }
  },
  mounted() {
    this.init();
  }
};