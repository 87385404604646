import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3eea7ada"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "pd32 colFlex"
};
const _hoisted_2 = {
  class: "ft16 boldFont"
};
const _hoisted_3 = {
  class: "ft12 mt24"
};
const _hoisted_4 = {
  class: "mt16 ft14 light"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_tag = _resolveComponent("el-tag");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_button = _resolveComponent("el-button");
  return _openBlock(), _createBlock(_component_el_row, {
    class: "SubjectCardContainer ptr",
    onMouseover: _cache[0] || (_cache[0] = $event => $data.btnVisible = true),
    onMouseleave: _cache[1] || (_cache[1] = $event => $data.btnVisible = false),
    onClick: $options.start,
    style: _normalizeStyle({
      backgroundImage: `url(${$data.bgSrc[$props.index % $data.bgSrc.length]})`
    })
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("span", _hoisted_2, _toDisplayString($props.data.name), 1), _createElementVNode("span", _hoisted_3, _toDisplayString($props.data.desc), 1), _createVNode(_component_el_row, {
      class: "mt24"
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.data.tags, (t, ti) => {
        return _openBlock(), _createBlock(_component_el_tag, {
          class: "mr12",
          key: ti,
          type: "info"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(t), 1)]),
          _: 2
        }, 1024);
      }), 128))]),
      _: 1
    }), _createElementVNode("span", _hoisted_4, "剩余练习次数:" + _toDisplayString($props.data.chanceAmt), 1)]), _withDirectives(_createVNode(_component_el_button, {
      size: "large",
      style: {
        "position": "absolute",
        "bottom": "0",
        "width": "100%",
        "background": "#3269FF",
        "color": "white",
        "border": "none"
      }
    }, {
      default: _withCtx(() => [_createTextVNode("自助练习")]),
      _: 1
    }, 512), [[_vShow, $data.btnVisible]])]),
    _: 1
  }, 8, ["onClick", "style"]);
}