import "core-js/modules/es.array.push.js";
import QuickScaleItem from "@/components/subject/QuickScale/QuickScaleItem";
import QuickScaleAnswerItem from "@/components/subject/QuickScale/QuickScaleAnswerItem";
export default {
  name: "QuickScaleRender",
  components: {
    QuickScaleAnswerItem,
    QuickScaleItem
  },
  props: {
    review: {
      type: Object,
      default: () => {
        return null;
      }
    },
    reviewMode: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {
        return null;
      }
    }
  },
  watch: {
    data() {
      this.init();
    }
  },
  data() {
    return {
      answers: [],
      reviewAns: null
    };
  },
  methods: {
    onChoosed(idx, val) {
      this.answers[idx - 1] = val;
      this.answers.join(',');
      this.$emit('answer', this.answers.join(','));
    },
    init() {
      this.answers = [];
      if (this.review != null) {
        this.reviewAns = this.review.answer.split(',');
        console.log('this.reviewAns', this.reviewAns);
      }
      for (let i = 0; i < 20; i++) {
        this.answers.push(0);
      }
      this.$emit('answer', this.answers.join(','));
    }
  },
  mounted() {
    this.init();
  }
};