export default {
  name: "BoardFlashingAnimation",
  props: {
    board: {
      type: Array,
      default: () => {
        return [];
      }
    },
    flash: {
      type: Array,
      default: () => {
        return [];
      }
    },
    speed: {
      type: Number,
      default: 100
    }
  },
  data() {
    return {
      active: null,
      playing: false
    };
  },
  methods: {
    init() {},
    play() {
      if (this.playing) {
        return;
      }
      this.playing = true;
      for (let i = 0; i < this.flash.length; i++) {
        setTimeout(() => {
          this.active = this.flash[i];
        }, this.speed * i);
      }
      setTimeout(() => {
        this.active = null;
        this.playing = false;
      }, this.speed * this.flash.length);
    }
  },
  mounted() {
    this.init();
  }
};