import AnswerButtonGroup from "@/components/train/AnswerButtonGroup";
import CircleInTriangle from "@/components/subject/E808APlus/CircleInTriangle";
export default {
  name: "E808APlusRender",
  components: {
    CircleInTriangle,
    AnswerButtonGroup
  },
  props: {
    review: {
      type: Object,
      default: () => {
        return null;
      }
    },
    reviewMode: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {
        return null;
      }
    }
  },
  watch: {
    data() {
      this.init();
    }
  },
  data() {
    return {
      letters: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
    };
  },
  methods: {
    onChoosed(l) {
      this.$emit('answer', l && l.length > 0 ? l.join(',') : null);
    },
    init() {
      this.$refs.answerBtns.init();
    }
  },
  mounted() {
    this.init();
  }
};