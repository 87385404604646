export default {
  name: "E808B3AnswerBtn",
  data() {
    return {
      selected: [0, 0]
    };
  },
  methods: {
    select(i) {
      this.selected[0] = this.selected[1];
      this.selected[1] = i;
      this.$emit('answer', `${this.selected[0]}${this.selected[1]}`);
    },
    init() {}
  },
  mounted() {
    this.init();
  }
};