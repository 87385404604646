import api from "@/api";
import LogicalQuestionSelector from "@/components/editor/LogicalQuestionSelector";
export default {
  name: "LogicalQuestionEditor",
  components: {
    LogicalQuestionSelector
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      questions: [],
      typeMap: {},
      drawer: false,
      updateIndex: 0
    };
  },
  methods: {
    toAdd(idx) {
      this.updateIndex = idx;
      this.drawer = true;
    },
    onSelected(qid) {
      api.updateTplQuestionId({
        tplId: this.data.id,
        updateIndex: this.updateIndex,
        questionId: qid
      }).then(() => {
        this.drawer = false;
        this.fetchQuestions();
      });
    },
    fetchQuestions() {
      api.previewTplQuestion(this.data.id).then(ret => {
        this.questions = ret;
      });
    },
    init() {
      this.fetchQuestions();
      api.getLogicalQuestionTypeMap().then(ret => {
        this.typeMap = ret;
      });
    }
  },
  mounted() {
    this.init();
  }
};