import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  width: "100",
  height: "100",
  xmlns: "http://www.w3.org/2000/svg"
};
const _hoisted_2 = ["stroke"];
const _hoisted_3 = ["stroke"];
const _hoisted_4 = ["stroke"];
const _hoisted_5 = ["stroke"];
const _hoisted_6 = ["stroke"];
const _hoisted_7 = ["stroke"];
const _hoisted_8 = ["stroke"];
const _hoisted_9 = ["stroke"];
const _hoisted_10 = ["stroke"];
const _hoisted_11 = ["stroke"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("svg", _hoisted_1, [_createElementVNode("line", {
    x1: "50",
    y1: "10",
    x2: "5",
    y2: "90",
    stroke: $data.color[$props.colors.charAt(0)],
    "stroke-width": "3"
  }, null, 8, _hoisted_2), _createElementVNode("line", {
    x1: "95",
    y1: "90",
    x2: "50",
    y2: "10",
    stroke: $data.color[$props.colors.charAt(1)],
    "stroke-width": "3"
  }, null, 8, _hoisted_3), _createElementVNode("line", {
    x1: "5",
    y1: "90",
    x2: "95",
    y2: "90",
    stroke: $data.color[$props.colors.charAt(2)],
    "stroke-width": "3"
  }, null, 8, _hoisted_4), _createElementVNode("line", {
    x1: "80",
    y1: "76",
    x2: "80",
    y2: "86",
    stroke: $data.color[$props.colors.charAt(3)],
    "stroke-width": "2"
  }, null, 8, _hoisted_5), _createElementVNode("line", {
    x1: "75",
    y1: "81",
    x2: "85",
    y2: "81",
    stroke: $data.color[$props.colors.charAt(3)],
    "stroke-width": "2"
  }, null, 8, _hoisted_6), _createElementVNode("line", {
    x1: "20",
    y1: "76",
    x2: "20",
    y2: "86",
    stroke: $data.color[$props.colors.charAt(4)],
    "stroke-width": "2"
  }, null, 8, _hoisted_7), _createElementVNode("line", {
    x1: "15",
    y1: "81",
    x2: "25",
    y2: "81",
    stroke: $data.color[$props.colors.charAt(4)],
    "stroke-width": "2"
  }, null, 8, _hoisted_8), _createElementVNode("line", {
    x1: "45",
    y1: "26",
    x2: "55",
    y2: "26",
    stroke: $data.color[$props.colors.charAt(5)],
    "stroke-width": "2"
  }, null, 8, _hoisted_9), _createElementVNode("line", {
    x1: "50",
    y1: "21",
    x2: "50",
    y2: "31",
    stroke: $data.color[$props.colors.charAt(5)],
    "stroke-width": "2"
  }, null, 8, _hoisted_10), _createElementVNode("circle", {
    cx: "50",
    cy: "63",
    r: "15",
    stroke: $data.color[$props.colors.charAt(6)],
    "stroke-width": "3",
    fill: "none"
  }, null, 8, _hoisted_11)]);
}