import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-67df2110"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "E808B3AnswerBtnContainer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_tag = _resolveComponent("el-tag");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, {
    class: "wd100 bothCenter mb12",
    style: {
      "border-bottom": "black 1px solid"
    }
  }, {
    default: _withCtx(() => [_createElementVNode("span", null, _toDisplayString($data.selected[0]) + _toDisplayString($data.selected[1]), 1)]),
    _: 1
  }), _createVNode(_component_el_row, {
    class: "wd100"
  }, {
    default: _withCtx(() => [(_openBlock(), _createElementBlock(_Fragment, null, _renderList([0, 1, 2, 3, 4, 5, 6, 7, 8, 9], i => {
      return _createVNode(_component_el_tag, {
        style: {
          "width": "10px"
        },
        type: $data.selected == i ? 'primary' : 'info',
        onClick: $event => $options.select(i),
        class: "ptr",
        key: i
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(i), 1)]),
        _: 2
      }, 1032, ["type", "onClick"]);
    }), 64))]),
    _: 1
  })]);
}