import LeftGroupList from "@/components/LeftGroupList";
import api from "@/api";
export default {
  name: "LogicalQuestionSelector",
  components: {
    LeftGroupList
  },
  data() {
    return {
      groups: [],
      groupId: null,
      questions: [],
      typeMap: {}
    };
  },
  methods: {
    confirm(qid) {
      this.$emit('confirm', qid);
    },
    onChoose(gid) {
      this.groupId = gid;
      this.fetchList();
    },
    fetchList() {
      api.getLogicalQuestionByGroupId(this.groupId).then(ret => {
        this.questions = ret.list;
      });
    },
    init() {
      api.getLogicalGroups().then(list => {
        this.groups = list;
        this.groupId = list[0].value;
        this.fetchList();
      });
      api.getLogicalQuestionTypeMap().then(ret => {
        this.typeMap = ret;
      });
    }
  },
  mounted() {
    this.init();
  }
};