import MossSymbol from "@/components/subject/Moss/MossSymbol";
import MossAnswerOption from "@/components/subject/Moss/MossAnswerOption";
export default {
  name: "MossRender",
  components: {
    MossAnswerOption,
    MossSymbol
  },
  props: {
    review: {
      type: Object,
      default: () => {
        return null;
      }
    },
    reviewMode: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {
        return null;
      }
    }
  },
  watch: {
    data() {
      this.init();
    }
  },
  data() {
    return {
      ans: [99, 99, 99, 99]
    };
  },
  methods: {
    onSelect(idx, val) {
      this.ans[idx] = val;
      for (let i of this.ans) {
        if (i >= 10) {
          return;
        }
      }
      this.$emit('answer', this.ans.join(','));
    },
    init() {
      this.ans = [99, 99, 99, 99];
    }
  },
  mounted() {
    this.init();
  }
};