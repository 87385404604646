import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-65d59a4a"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["onClick"];
const _hoisted_2 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_row = _resolveComponent("el-row");
  return _openBlock(), _createBlock(_component_el_row, {
    class: "E808B2BtnGroupContainer wd100"
  }, {
    default: _withCtx(() => [(_openBlock(), _createElementBlock(_Fragment, null, _renderList([0, 1, 3], i => {
      return _createVNode(_component_el_col, {
        span: 8,
        class: "bothCenter",
        key: `op_1_${i}`
      }, {
        default: _withCtx(() => [_createElementVNode("span", {
          onClick: $event => $options.select(0, i),
          class: _normalizeClass({
            'ptr active': $data.values[0] == i,
            'ptr': $data.values[0] != i
          })
        }, "[" + _toDisplayString(i) + "]", 11, _hoisted_1)]),
        _: 2
      }, 1024);
    }), 64)), (_openBlock(), _createElementBlock(_Fragment, null, _renderList([0, 1, 3], i => {
      return _createVNode(_component_el_col, {
        span: 8,
        class: "bothCenter",
        key: `op_2_${i}`
      }, {
        default: _withCtx(() => [_createElementVNode("span", {
          onClick: $event => $options.select(1, i),
          class: _normalizeClass({
            'ptr active': $data.values[1] == i,
            'ptr': $data.values[1] != i
          })
        }, "[" + _toDisplayString(i) + "]", 11, _hoisted_2)]),
        _: 2
      }, 1024);
    }), 64))]),
    _: 1
  });
}