import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1139692f"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "BlackWhiteDotGraphContainer"
};
const _hoisted_2 = {
  key: 0,
  src: "https://cdn.waityou.online/264e0a6a-9d8a-3fa3-df42-aaae09fec847.svg",
  class: "sqr32"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_row = _resolveComponent("el-row");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.board, (r, ri) => {
    return _openBlock(), _createBlock(_component_el_row, {
      key: `board_${ri}`,
      class: "bothCenter"
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(r, (e, ei) => {
        return _openBlock(), _createElementBlock("div", {
          class: "boardBox bothCenter",
          key: `r_${ri}_${ei}`
        }, [e > 0 ? (_openBlock(), _createElementBlock("img", _hoisted_2)) : _createCommentVNode("", true)]);
      }), 128))]),
      _: 2
    }, 1024);
  }), 128))]);
}