import "core-js/modules/es.array.push.js";
export default {
  name: "AnswerButtonGroup",
  props: {
    optionWidth: {
      type: String,
      default: '100px'
    },
    optionBothCenter: {
      type: Boolean,
      default: true
    },
    review: {
      type: Object,
      default: () => {
        return null;
      }
    },
    reviewMode: {
      type: Boolean,
      default: false
    },
    single: {
      type: Boolean,
      default: true
    },
    options: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  watch: {
    options() {
      this.init();
    }
  },
  data() {
    return {
      letters: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
      selected: {}
    };
  },
  methods: {
    onClick(op) {
      if (this.review != null) {
        return;
      }
      if (this.single) {
        for (let o of this.options) {
          if (op != o) {
            this.selected[o] = false;
          }
        }
      }
      this.selected[op] = !this.selected[op];
      let choosed = [];
      for (let o of this.options) {
        if (this.selected[o]) {
          choosed.push(o);
        }
      }
      this.$emit('choose', choosed);
    },
    init() {
      this.selected = {};
      if (this.review != null) {
        for (let op of this.review.answer.split(',')) {
          this.selected[op] = true;
        }
      }
    }
  },
  mounted() {
    this.init();
  }
};