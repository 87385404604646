import PriceCalculateImage from "@/components/subject/PriceCalculate/PriceCalculateImage";
import AnswerButtonGroup from "@/components/train/AnswerButtonGroup";
export default {
  name: "PriceCalculateRender",
  components: {
    PriceCalculateImage,
    AnswerButtonGroup
  },
  props: {
    review: {
      type: Object,
      default: () => {
        return null;
      }
    },
    reviewMode: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {
        return null;
      }
    }
  },
  watch: {
    data() {
      this.init();
    }
  },
  methods: {
    onChoosed(l) {
      this.$emit('answer', l && l.length > 0 ? l[0] : null);
    },
    init() {}
  },
  mounted() {
    this.init();
  }
};